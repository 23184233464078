.groups {
    display: flex;
    flex-direction: column;

    &__table {

        &__el__img {
            cursor: pointer;
            align-self: center;
        }

        &__row {
            display: flex;
            justify-content: space-between;
            padding: 1.71rem 0 1.71rem;
            border-top: 1px solid #BCB7B3;
            cursor: pointer;

            &:first-child {
                border: none;
            }

            &:nth-child(2) {
                border: none;
            }
        }

        &__title {
            display: flex;
            justify-content: space-between;
            margin: 2.56rem 0 3rem;

            &__el {
                justify-self: center;
                white-space: nowrap;
            }
        }
    }
}