.contact {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-top: 17.1rem;

    @media (max-width: 850px) {
        margin-top: 70px;
    }

    &__nav {
        display: flex;
        flex-direction: column;
        position: absolute;
        left: 6.8rem;
        gap: 2.56rem;

        @media (max-width: 1000px) {
            left: 5rem;
        }

        @media (max-width: 850px) {
            flex-direction: row;
            position: inherit;
            margin-left: auto;
            margin-right: auto;
            left: 0;
            margin-bottom: 2.56rem;
        }

        &__link {
            text-decoration: none;
            font-weight: 400;
            font-size: 3rem;
            line-height: 4.2rem;
            color: #56514D;
            transition: all .3s;

            &:hover {
                color: #E75549;
            }

            &.page {
                color: $black;
            }


            @media (max-width: 600px) {
                font-size: 25px;
            }
        }
    }

    &__info {
        display: flex;
        flex-direction: column;
        align-self: center;
        min-height: calc(100vh - 10.2rem);
        margin-left: 8.56rem;
        width: 61.6rem;

        @media (max-width: 850px) {
            margin-left: 0;
        }

        
        @media (max-width: 550px) {
            width: 100%;
            padding: 0 20px;
        }

        &__el {
            margin-top: 2.56rem;
            display: flex;
            flex-direction: column;
            gap: 1.54rem;

            &__title {
                font-family: 'Roboto', sans-serif;
                font-weight: 400;
                font-size: 1.71rem;
                line-height: 2rem;
                color: #BCB7B3;
            }

            &__info {
                font-family: 'Roboto', sans-serif;
                border: 1px solid #BCB7B3;
                border-radius: 30px;
                padding: 1.71rem 2.56rem;
                font-weight: 400;
                font-size: 1.71rem;
                line-height: 2rem;
                color: #0B0A0A;
                background-repeat: no-repeat;
                background-size: 3.43rem;
                background-position: calc(100% - 1rem);

                @media (max-width: 800px) {
                    font-size: 16px;
                    padding: 10px 70px 10px 16px;
                    background-size: 30px;
                }

                &.phone {
                    background-image: url('./../images/contact/phone.svg');
                }

                &.adress {
                    background-image: url('./../images/contact/adress.svg');
                }

                &.email {
                    background-image: url('./../images/contact/email.svg');
                }
            }
        }
    }

    &__questions {
        display: flex;
        justify-content: center;
        min-height: calc(100vh - 10.2rem);
        margin-left: 8.56rem;


        @media (max-width: 850px) {
            margin-left: 0;
        }

        &__wrapper {
            padding: 0 10vw;
            display: flex;
            flex-direction: column;
        }

        &__left {
            &__text {
                font-weight: 400;
                font-size: 1.71rem;
                line-height: 2.56rem;
                color: #BCB7B3;
                margin-bottom: 2.56rem;
            }
        }

        &__right {
            &__title {
                font-family: 'Roboto', sans-serif;
                font-size: 4.62rem;
                font-weight: 400;
                line-height: 120%;
                letter-spacing: -0.021em;
                color: $black;
                white-space: pre-line;
            }

            &__text {
                font-weight: 400;
                font-size: 3.42rem;
                line-height: 4.1rem;
                color: #56514D;
                margin: 4.3rem 0;
                white-space: pre-line;
            }

            &__form {
                display: flex;
                flex-direction: column;
                width: 61.6rem;

                &__el {
                    font-family: 'Roboto', sans-serif;
                    border: 1px solid #BCB7B3;
                    border-radius: 30px;
                    margin-bottom: 1.3rem;
                    padding: 1.71rem 0 1.71rem 2.56rem;
                    font-size: 1.71rem;
                    line-height: 2.1rem;
                    color: $black;


                    &:focus {
                        outline: none;
                        border-color: $black;
                    }

                    &.question {
                        height: 17.1rem;
                        resize: none;
                    }

                    &::placeholder {
                        color: #BCB7B3;
                    }
                }

                &__button {
                    margin-top: 1.3rem;
                    background-image: url('./../images/contact/arrow.svg');
                    background-repeat: no-repeat;
                    background-position: 0.4rem;
                    background-size: 3.42rem;
                    align-self: flex-start;
                    background-color: #E75549;
                    border-radius: 50px;
                    border: none;
                    font-size: 1.54rem;
                    line-height: 2.05rem;
                    text-transform: uppercase;
                    color: #FDFAFA;
                    padding: 1.05rem 1.36rem 1.05rem 4.45rem;
                    cursor: pointer;
                }
            }
        }
    }

    &__modal {
        &__wrapper {
            padding: 2.56rem 10.44rem 3.42rem 10.44rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        &__content {
            display: flex;
            flex-direction: column;

            &__header {
                display: flex;
                flex-direction: column;
                align-items: center;

                &__img {
                    padding-bottom: 1.28rem;
                    height: 10.2rem;
                    width: 10.2rem;
                }

                &__text {
                    font-weight: 400;
                    font-size: 3.42rem;
                    line-height: 4.1rem;
                    color: #14AE5C;
                    padding-bottom: 2.56rem;
                    white-space: nowrap;

                    &.fail {
                        color: #E75549;
                    }
                }
            }

            &__info__text {
                font-family: 'Roboto', sans-serif;
                text-align: center;
                font-weight: 400;
                font-size: 1.71rem;
                line-height: 2.05;
                padding-bottom: 2.56rem;
                color: #56514D;
            }

            &__button {
                display: flex;
                align-items: center;
                background-color: white;
                border: none;
                background: $black;
                border-radius: 50px;
                color: $white;
                cursor: pointer;

                &__text {
                    padding: 1.05rem 1.37rem 1.05rem 0.35rem;
                    font-weight: 400;
                    font-size: 1.54rem;
                    line-height: 2rem;
                    text-transform: uppercase;
                    color: #FDFAFA;

                    @media (max-width: 850px) {
                    padding: 1.05rem 1.37rem 1.05rem 1rem;
                    }
                }

                &__img {
                    height: 3.42rem;
                    width: 3.42rem;
                    margin-left: 0.4rem;
                }
            }

            &__button-close {
                margin-top: 2.56rem;
                display: flex;
                align-items: center;
                background-color: $white;
                border: none;
                color: $white;
                cursor: pointer;

                &__text {
                    padding-left: 0.5rem;
                    font-weight: 400;
                    font-size: 1.54rem;
                    line-height: 2rem;
                    color: #FDFAFA;
                    color: #BCB7B3;
                }
            }
        }
    }

    &__price {
        margin: 10.3rem 0;
        display: flex;
        align-items: center;
        justify-content: center;

        &__wrapper {
            width: 77rem;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        &__title {
            font-family: 'Roboto', sans-serif;
            font-size: 4.62rem;
            font-weight: 400;
            line-height: 120%;
            letter-spacing: -0.021em;
            margin-bottom: 4.3rem;
            position: relative;
            color: #56514D;

            &>.black {
                font-family: 'Roboto', sans-serif;
                color: $black;
            }
        }

        &__box {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 2.56rem;

            &__card {
                width: 36rem;
                background: #FDFAFA;
                box-shadow: 0px 2.91rem 4.53rem rgba(79, 79, 79, 0.07);
                border-radius: 30px;
                transition: all .3s;

                &:hover {
                    box-shadow: 0px 2.91rem 4.53rem rgba(79, 79, 79, 0.1);
                }


                &__wrapper {
                    padding: 2.56rem;
                    display: flex;
                    flex-direction: column;
                }

                &__title {
                    align-self: flex-start;
                    padding: 0.5rem 1.3rem;
                    margin-bottom: 0.4rem;
                    font-weight: 400;
                    font-size: 2rem;
                    line-height: 2.5rem;
                    text-transform: uppercase;
                    color: $black;
                    background: #FDFAFA;
                    box-shadow: 0px 0px 1.3rem rgba(79, 79, 79, 0.08);
                    border-radius: 50px;
                }

                &__price {
                    font-family: 'Roboto', sans-serif;
                    font-weight: 400;
                    font-size: 4.6rem;
                    line-height: 120%;
                    letter-spacing: -0.021em;
                    color: $black;
                }

                &__button {
                    margin-top: 2.22rem;
                    background-image: url('./../images/contact/arrow.svg');
                    background-repeat: no-repeat;
                    background-position: 0.4rem;
                    background-size: 3.42rem;
                    align-self: flex-start;
                    background-color: #E75549;
                    border-radius: 50px;
                    border: none;
                    font-size: 1.54rem;
                    line-height: 2rem;
                    text-transform: uppercase;
                    color: #FDFAFA;
                    padding: 1.05rem 1.36rem 1.05rem 4.45rem;
                    cursor: pointer;

                    &.telegram {
                        background-image: url('./../images/contact/telegram-icon.svg');
                    }
                }

            }

            &__text {
                font-family: 'Roboto', sans-serif;
                width: 36rem;
                font-style: normal;
                font-weight: 400;
                font-size: 1.71rem;
                line-height: 2.05rem;
                color: #56514D;

                & font {
                    font-family: 'Roboto', sans-serif;
                }

                @media (max-width: 850px) {
                    margin-top: 10px;
                }
            }
        }
    }
}

@media (max-width: 787px) {
    .contact__questions__wrapper {
        padding: 0 5vw;
    }

    .contact__questions__left__text,
    .contact__questions__right__text {
        letter-spacing: -0.03em;
        font-size: 18px;
    }

    .contact__questions__right__title,
    .contact__price__title {
        font-size: 24px;
    }

    .contact__questions__right__form {
        width: 400px;
    }

    .contact__price__wrapper {
        width: 550px;
    }

    .contact__price__box__card__price {
        font-size: 36px;
    }

    .contact__price__box__card__title {
        font-size: 12px;
    }

    .contact__price__box__text {
        font-size: 15px;
    }

    .contact__price__box__card__button {
        margin-top: 5px;
    }

    .contact__price__box__card__button,
    .contact__questions__right__form__button {
        font-size: 14px;
    }

}

@media (max-width: 600px) {
    .contact__questions__left__text {
        margin-bottom: 16px;
    }

    .contact__questions__right__text {
        line-height: normal;
    }

    .contact__questions__right__form {
        width: 350px;
    }

    .contact__questions__right__form__button {
        align-self: center;
    }

    .contact__price__wrapper {
        width: 100%;
        padding: 0 5vw;
    }

    .contact__price__title {
        font-size: 28px;
        text-align: left;
        font-weight: 400;
    }

    .contact__price__box {
        display: flex;
        flex-direction: column;
    }

    .contact__price__box__text {
        width: 350px;
    }

    .contact__modal__content__button {
        align-items: center;
    }

    .contact__modal__content {
        width: 358px;
    }

    .contact__price__box__card,
    .contact__price__box__text {
        width: 100%;
        align-self: flex-start;
    }
}

@media (max-width: 370px) {
    .contact__questions__right__form {
        width: 100%;
    }
}