.mainPage {
    &__start {
        position: relative;
        margin-bottom: 100px;
    }

    &__first {
        position: relative;
        height: calc(100vh - 6.84rem + 10px);
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 10px 10px 10px;


        &__icons {
            width: 100%;
            position: absolute;
            bottom: 0;
            z-index: 1000;
        }

        &__wrapper {
            overflow: hidden;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            border-radius: 30px;
            position: relative;
            margin-bottom: 10px;
        }

        &__bg {
            position: absolute;
            background-color: #E75549;
            width: 1000px;
            height: 1000px;
            border-radius: 100%;
            transform-origin: center center;
        }

        &__title {
            width: 95%;
            height: 500px;
            margin-top: 100px;
        }

        &__titleSecond {
            position: absolute;
            width: 96%;
            height: 500px;
            margin-top: 100px;
            z-index: 1000;
        }

        &__arrow {
            margin-top: 65px;
            width: 200px;
            height: 200px;
        }
    }

    &__solution {
        width: 100%;
        top: 0;
        position: absolute;
        height: calc(100vh - 80px);
        padding: 0 10px 10px 10px;

        &__wrapper {
            overflow: hidden;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            border-radius: 30px;
            position: relative;
        }
    }

    &__leaders {
        height: calc(100vh - 6.84rem);

        &__wrapper {
            height: calc(100vh - 6.84rem);
            max-width: 1920px;
            padding: 0 80px;
            display: flex;
            justify-content: space-between;
            position: relative;
            margin-left: auto;
            margin-right: auto;
        }

        &__button-about {
            background: none;
            border: none;
            display: flex;
            cursor: pointer;
            margin-bottom: 10px;
            align-self: flex-end;
            transition: all .3s;

            &:hover {
                opacity: 0.8;
            }

            &__img {
                width: 29px;
                height: 29px;
                margin-right: 8px;
            }

            &__text {
                font-size: 20px;
                line-height: 24px;
                text-transform: uppercase;
                color: #56514D;
                padding-top: 2px;
            }
        }

        &__left {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            position: relative;
            overflow: hidden;

            &__title {
                font-weight: 400;
                font-size: 40px;
                line-height: 48px;
                color: $black;
                padding-bottom: 70px;
                white-space: pre-line;
            }

            &__img {
                width: 420px;
                height: 366px;
                margin-bottom: 50px;
                opacity: 0;
            }
        }

        &__right {
            width: 60%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            &__topImg {
                width: 568px;
                height: 419px;
                opacity: 0;
                margin-top: 20px;
            }

            &__secondImg {
                width: 720px;
                height: 304px;
                margin-bottom: 50px;
            }

            &__textBox {
                height: 325px;

                &__el {
                    margin-top: 10px;
                    color: #56514D;
                    display: flex;
                    flex-direction: column;
                    overflow: hidden;
                    height: 300px;
                    width: 100%;

                    & span {
                        font-family: 'Roboto', sans-serif;
                        min-height: 300px;
                        font-weight: 400;
                        font-size: 46px;
                        line-height: 120%;
                        letter-spacing: -0.023em;
                        margin-bottom: 50px;
                        white-space: pre-line;

                        & font {
                            font-family: 'Roboto', sans-serif;
                            white-space: pre-line;
                        }
                    }
                }
            }
        }
    }

    &__subscribes {
        display: flex;
        align-items: center;
        justify-content: center;

        &__suggetions {
            height: 100vh;
            margin-top: 0;
            width: 100%;
            position: relative;

            &__card {
                width: 30vw !important;
                position: absolute;
                z-index: 10;
                height: 720px;

                @media (min-width: 1650px) {
                    height: 840px;
                }
            }
        }

        &__wrapper {
            display: flex;
            justify-content: space-between;
            padding: 4vw;
            width: 100%;
        }

        &__box {
            display: flex;
            flex-direction: column;
            width: 70%;
            opacity: 1;
            z-index: 1;

            &__title {
                font-family: 'Roboto', sans-serif;
                font-weight: 700;
                font-size: 96px;
                letter-spacing: -0.02em;
                line-height: 115px;
                letter-spacing: -0.02em;
                color: $black;
                margin-bottom: 50px;
                white-space: pre-line;
            }

            &__text {
                font-family: 'Roboto', sans-serif;
                font-weight: 400;
                font-size: 20px;
                line-height: 24px;
                color: #56514D;
                white-space: pre-line;
            }
        }
    }

}

.mainPage__leaders__right__mobileFirstImg,
.mainPage__leaders__right__mobileSecondImg,
.mainPage__leaders__right__mobileThirdImg {
    display: none;
}

.first-text {
    display: inline-block;
}

@media (max-width: 1600px) {

    .mainPage__first__title,
    .mainPage__first__titleSecond {
        margin-top: 0;
    }

    .mainPage__first__arrow {
        margin-top: 0;
        height: 150px;
    }

    .mainPage__leaders__right__textBox__el span {
        font-size: 30px;
    }

    .mainPage__leaders__right__textBox__el {
        height: 190px;
    }

    .mainPage__subscribes__box__title {
        font-size: 80px;
        line-height: 90px;
    }

    .mainPage__subscribes__suggetions {
        height: calc(100vh - 80px);
        padding-top: 95px;
    }

    .mainPage__subscribes__box__title {
        padding-top: 95px;
    }



    .mainPage__subscribes__wrapper {
        margin-bottom: 170px;
    }

    .mainPage__leaders__right__secondImg {
        width: 486px;
        height: 205px;
    }

    .mainPage__leaders__left__img {
        width: 315px;
        height: 238px;
    }

    .mainPage__leaders__right__topImg {
        width: 426px;
        height: 315px;
    }
}

@media (max-width: 1400px) {

    .mainPage__first__title,
    .mainPage__first__titleSecond {
        margin-top: 0;
    }

    .mainPage__leaders__right__textBox__el {
        height: 200px;
        width: 90%;
    }

    .mainPage__leaders__right {
        width: 50%;
    }
}

@media (max-width: 1250px) {
    .mainPage__subscribes__suggetions__card {
        height: 630px;
    }
}

@media (max-width: 1100px) {
    .mainPage__leaders__right__topImg {
        margin-top: 0;
    }

    .mainPage__leaders__left__title {
        padding-bottom: 0;
    }

    .mainPage__leaders__wrapper {
        padding: 0 40px;
    }

    .mainPage__leaders__left__title {
        font-size: 32px;
    }

    .mainPage__leaders__right__textBox__el span {
        font-size: 24px;
    }
}

@media (max-width: 1200px) {
    .mainPage__subscribes__box__title {
        font-size: 50px;
        line-height: 60px;
    }

    .mainPage__subscribes__wrapper {
        margin-bottom: 300px;
    }

    .mainPage__subscribes__suggetions,
    .mainPage__subscribes__box__title {
        padding-top: 110px;
    }
}

@media (max-width: 1060px) {
    .mainPage__subscribes__suggetions__card {
        height: 570px;
    }
}

@media (max-width: 1000px) {

    .mainPage__subscribes__wrapper {
        margin-bottom: 100px;
    }

    .mainPage__subscribes__wrapper {
        flex-direction: column-reverse;
        align-items: center;
    }

    .mainPage__subscribes__suggetions {
        padding-top: 40px;
    }

    .mainPage__subscribes__suggetions__card,
    .mainPage__subscribes__suggetions {
        width: 100% !important;
        height: auto;
    }
}

@media (max-width: 1000px) {
    .mainPage__leaders__right__secondImg {
        width: 340px;
        height: 145px;
    }

    .mainPage__leaders__left__img {
        width: 315px;
        height: 238px;
    }

    .mainPage__leaders__right__topImg {
        width: 298px;
        height: 221px;
    }

    .mainPage__leaders__right__textBox__el {
        margin-top: 50px;
        height: 250px;
    }

    .mainPage__leaders__left__title {
        font-size: 28px;
    }

    .mainPage__leaders__wrapper {
        padding: 0 16px;
    }

    .mainPage__subscribes__suggetions {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: auto;
    }

    .mainPage__subscribes__suggetions__card {
        position: inherit;
        margin-top: 20px;
    }
}

@media (max-width: 600px) {
    .mainPage__subscribes__box {
        width: 100%;
        align-self: flex-start;
    }

    .mainPage__subscribes__box__title {
        padding-top: 60px;
        font-weight: 600;
        font-size: 48px;
        line-height: 58px;
        letter-spacing: -0.02em;
        margin-bottom: 24px;

        white-space: normal;
    }

    .mainPage__subscribes__box__text {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #56514D;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;

        white-space: normal;
    }

    .mainPage__leaders__left__title {
        font-weight: 400;
        font-size: 24px;
        line-height: 29px;
        width: 50%;
        margin: 0 0;
    }

    .mainPage__leaders__right__textBox__el span {
        font-size: 28px;
    }

    .mainPage__leaders__button-about__text {
        font-size: 16px;
        padding-top: 3px;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
    }

    .mainPage__leaders__button-about__img {
        width: 24px;
        height: 24px;
    }

    .mainPage__leaders__button-about {
        align-items: center;
    }

    .mainPage__leaders__right__topImg {
        display: none;
    }

    .mainPage__leaders__left__img {
        display: none;
    }

    .mainPage__leaders__right__secondImg {
        display: none;
    }

    .mainPage__leaders__right {
        width: 100%;
    }

    .mainPage__leaders__button-about {
        align-self: flex-start;
    }

    .mainPage__leaders__right__textBox {
        height: auto;
    }

    .mainPage__leaders__right__textBox__el {
        width: 100%;
    }

    .mainPage__leaders__right__mobileFirstImg {
        opacity: 1;
        width: 355px;
        height: 155px;
        margin-bottom: 10px;
    }

    .mainPage__leaders__right__mobileSecondImg {
        width: 173px;
        height: 151px;
        position: absolute;
        opacity: 0;
        right: 16px;
    }

    .mainPage__leaders__right__mobileThirdImg {
        width: 173px;
        height: 115px;
        position: absolute;
        opacity: 0;
        right: 16px;
        bottom: 50px;
    }

    .mainPage__leaders__right__mobileFirstImg,
    .mainPage__leaders__right__mobileSecondImg,
    .mainPage__leaders__right__mobileThirdImg {
        display: block;
    }

    .mainPage__leaders__right__textBox__el span font,
    .mainPage__leaders__right__textBox__el span,
    .mainPage__leaders__left__title {
        white-space: normal;
    }
}

@media (max-height: 700px),
(max-width: 370px) {
    .mainPage__leaders__right__mobileSecondImg {
        width: 121px;
        height: 105px;
    }

    .mainPage__leaders__right__mobileFirstImg {
        width: 248px;
        height: 105px;
    }
}

@media (max-width: 400px) {
    .mainPage__leaders__right__mobileFirstImg {
        width: 300px;
    }
}

@media (max-width: 340px) {
    .mainPage__leaders__right__textBox__el span {
        font-size: 24px;
    }
}