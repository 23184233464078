.bookingPayment {
    display: flex;
    flex-direction: column;
    margin-top: 17.1rem;

    &__header {
        display: flex;
        position: relative;

        &__back-button {
            position: absolute;
            border: none;
            border-radius: 100%;
            background-color: white;
            left: -5.56rem;
            top: 2.65rem;

            @media (max-width: 1050px) {
                left: 0;
            }

            &__img {
                cursor: pointer;
            }
        }

        &__title {
            font-family: 'Roboto', sans-serif;
            font-weight: 700;
            font-size: 8.2rem;
            letter-spacing: -0.02em;
            line-height: 9.85rem;
            letter-spacing: -0.02em;
            color: $black;

            @media (max-width: 1050px) {
                margin-left: 7.56rem;
            }
        }
    }

    &__container {
        display: flex;
        justify-content: space-between;

        &__left {

            &__el:first-child {
                border-bottom: 1px solid #BCB7B3;
                margin-bottom: 2.56rem;
            }

            &__el {
                display: flex;


                &__number {
                    width: 4.1rem;
                    height: 4.1rem;
                    margin-right: 2.56rem;
                }

                &__box {
                    display: flex;
                    flex-direction: column;

                    &__title {
                        font-size: 3.42rem;
                        line-height: 4.1rem;
                        color: $black;
                        margin-bottom: 2.56rem;
                    }

                    &__text {
                        font-family: 'Roboto', sans-serif;
                        font-size: 1.71rem;
                        line-height: 2rem;
                        color: #56514D;
                        margin-bottom: 2.56rem;

                        &>.blue {
                            color: #5480A0;
                        }
                    }
                }

                &__buttons {
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 4.3rem;
                    gap: 2rem;

                    &__title {
                        font-family: 'Roboto', sans-serif;
                        font-weight: 400;
                        font-size: 1.71rem;
                        line-height: 2rem;
                        color: #56514D;
                        margin-right: 1.71rem;

                        &.interkassa {
                            white-space: pre;

                            @media (max-width: 850px) {
                                font-size: 15px;
                            }

                            @media (max-width: 400px) {
                                white-space: normal;
                            }
                        }

                        &__mobile {
                            display: none;
                            font-family: 'Roboto', sans-serif;
                            font-weight: 400;
                            font-size: 1.71rem;
                            line-height: 2rem;
                            color: #56514D;
                            margin-bottom: 1rem;
                        }
                    }

                    &__button {
                        background: #FDFAFA;
                        border: none;
                        border-radius: 15px;
                        cursor: pointer;
                        box-shadow: 0px 2.9rem 4.53rem rgba(79, 79, 79, 0.03);
                        margin-right: 2.56rem;

                        &__img {
                            padding: 2rem 1.35rem;
                        }
                    }

                    &__button.actived {
                        box-shadow: 0px 0.7rem 1.15rem rgba(79, 79, 79, 0.13);
                    }
                }

                &__pay-button {
                    margin: 4.3rem 0 8.56rem;
                    display: flex;
                    align-items: center;
                    background-color: #E75549;
                    border-radius: 50px;
                    border: none;
                    font-family: 'Roboto', sans-serif;
                    font-size: 1.71rem;
                    line-height: 2rem;
                    text-transform: uppercase;
                    color: $black;
                    cursor: pointer;

                    &__text {
                        padding: 1rem 1.35rem 1rem 0.35rem;
                        color: $white;
                    }

                    &__img {
                        margin: 0 0.35rem;
                        height: 3.42rem;
                        width: 3.42rem;
                    }
                }
            }
        }

        &__right {
            display: flex;
            flex-direction: column;
            justify-self: center;

            &__wrapper {
                padding: 2.56rem 0;
            }

            &__title {
                margin-bottom: 2.56rem;
                font-size: 3.42rem;
                line-height: 4.1rem;
                color: $black;
            }

            &__list {
                border-bottom: 1px solid #BCB7B3;
                margin-bottom: 1.3rem;

                &__el {
                    display: flex;
                    margin-bottom: 1.3rem;

                    &__text {
                        font-family: 'Roboto', sans-serif;
                        font-size: 1.71rem;
                        line-height: 2rem;
                        color: $black;
                        margin-left: 0.5rem;
                    }
                }
            }

            &__price {
                display: flex;
                align-items: center;

                &__result {
                    font-family: 'Roboto', sans-serif;
                    font-size: 1.71rem;
                    line-height: 2rem;
                    color: #BCB7B3;
                }

                &__money {
                    font-family: 'Roboto', sans-serif;
                    margin: 0 0.9rem 0 2.56rem;
                    font-size: 4.6rem;
                    line-height: 120%;
                    letter-spacing: -0.021em;
                    color: $black;
                }

                &__monthly {
                    font-family: 'Roboto', sans-serif;
                    font-size: 1.71rem;
                    line-height: 2rem;
                    color: #56514D;
                }
            }
        }
    }

    &__modal {

        &__content {
            padding: 2.56rem 10.45rem;

            &__header {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin-bottom: 2.15rem;

                &__img {
                    margin-bottom: 1.3rem;
                    height: 10.2rem;
                    width: 10.2rem;

                    @media (max-width: 850px) {
                        height: 80px;
                        width: 80px;
                    }
                }

                &__text {
                    font-size: 3.42rem;
                    line-height: 4.1rem;
                    color: #14AE5C;
                    text-align: center;
                }
            }

            &__info {
                width: 34.25rem;

                &__title {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 1.71rem;
                    line-height: 2rem;
                    text-align: center;
                    color: #56514D;
                    margin-bottom: 2.56rem;
                    font-family: 'Roboto', sans-serif;
                }

                &__list {
                    margin-bottom: 2.56rem;

                    &.big {
                        width: 100%;
                    }

                    &__el {
                        display: flex;
                        margin-bottom: 1.3rem;

                        &__text {
                            font-family: 'Roboto', sans-serif;
                            font-size: 1.71rem;
                            line-height: 2rem;
                            color: $black;
                            margin-left: 0.5rem;

                            &.green {
                                color: #14AE5C;
                            }
                        }
                    }
                }
            }

            &__button {
                display: flex;
                align-items: center;
                background-color: #E75549;
                border-radius: 50px;
                border: none;
                font-family: 'Roboto', sans-serif;
                font-size: 1.71rem;
                line-height: 2rem;
                text-transform: uppercase;
                color: $black;
                cursor: pointer;

                &__text {
                    padding: 1rem 1.35rem 1rem 0.35rem;
                    color: $white;
                }

                &__img {
                    padding: 0.4rem;
                }

            }
        }
    }

    &__modal {

        &__button {
            width: auto;
        }

        &__title {
            color: #E75549;
        }
    }
}


@media (max-width: 850px) {
    .bookingDate__container__right__list {
        border: none;
    }

    .bookingPayment {
        padding-bottom: 0;
        margin-bottom: 57px;

        &__wrap {
            padding: 0 16px;
        }

        &__header {

            &__back-button {
                top: 4px;
                left: 0;

                &__img {
                    width: 24px;
                    height: 24px;
                }
            }


            &__title {
                font-size: 28px;
                line-height: 120%;
                margin-left: 36px;
            }
        }

        &__container {
            flex-direction: column-reverse;

            &__right {
                width: 100%;
                margin: 2rem 0 20px 0;

                &__wrapper {
                    width: 100%;
                    padding: 1.71rem 0;
                }

                &__title {
                    font-size: 22px;
                    line-height: 26px;
                    margin-bottom: 2rem;
                }

                &__list {
                    border-bottom: 1px solid #BCB7B3;

                    &__el__text {
                        font-size: 16px;
                        line-height: 19px;
                    }
                }

                &__price {
                    margin-top: 16px;

                    &__result {
                        font-size: 16px;
                        line-height: 19px;
                    }

                    &__money {
                        font-size: 28px;
                        line-height: 120%;
                        margin-left: 12px;
                    }
                }
            }
        }
    }

    .bookingPayment__container__left__el__buttons {
        width: auto;

        // &__title {
        //     display: none;

        //     &__mobile {
        //         display: block;
        //     }
        // }

        &__button {
            width: 47%;

            &__img {
                width: 90%;
            }
        }
    }

    .bookingPayment__container__left__el__pay-button__text {
        font-size: 14px;
        line-height: 21px;
        padding: 0 8px 0 4px;
    }

    .bookingPayment__container__left__el__pay-button__img {
        width: 24px;
        height: 24px;
        margin: 4px 0px 4px 4px;
    }

    .bookingPayment__modal__content {
        width: calc(100vw - 32px);
        padding: 24px 16px 40px;
    }

    .bookingPayment__modal__content__header__img {
        width: 80px;
        height: 80px;
    }

    .bookingPayment__modal__content__header__text {
        line-height: 26px;
        font-size: 22px;
    }


    .bookingPayment__modal__content__info__list__el__text,
    .bookingPayment__modal__content__info__title {
        font-size: 16px;
        line-height: 19px;
    }

    .bookingPayment__modal__content__info {
        width: auto;
    }

    .account__modal__gmail__content__button__img {
        height: 24px;
        width: 24px;
        padding: 0 0 0 4px;
    }

    .account__modal__gmail__content__button__text {
        font-size: 14px;
        line-height: 21px;
        padding: 6px 16px 6px 8px;
    }

    .bookingPayment__modal__content__header {
        margin-bottom: 24px;
    }

    .bookingPayment__modal__content__info__list {
        margin-top: 24px;
    }

    .account__modal__gmail__content__cancelButton__text {
        font-size: 16px;
        margin-left: 6px;
    }

    .account__modal__gmail__content__cancelButton__img {
        width: 20px;
        height: 20px;
        padding-top: 2px;
    }
}