.filters {
    display: flex;
    gap: 2.56rem;

    @media (max-width: 850px) {
        overflow-x: auto;
    }

    &__el {
        white-space: nowrap;
        font-weight: 400;
        font-size: 1.54rem;
        line-height: 2.4rem;
        letter-spacing: -0.03em;
        text-transform: uppercase;
        color: $black;
        border: none;

        background-color: $white;
        box-shadow: 0px 0px 1.71rem rgba(79, 79, 79, 0.11);
        border-radius: 2.56rem;
        padding: 0.35rem 1.34rem;

        cursor: pointer;
        transition: all .3s;

        &:hover {
            box-shadow: 0px 0px 0.85rem rgba(79, 79, 79, 0.15);
        }

        @media (max-width: 850px) {
            font-size: 13px;
        }

        @media (max-width: 600px) {
            box-shadow: 0px 0px 0px rgba(79, 79, 79, 0);
            border: 1px rgba(79, 79, 79, 0.15);
        }

        &.chosen {
            color: $white;
            background-color: #E75549;
            box-shadow: none;
        }
    }
}