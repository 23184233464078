body.active-modal {
    overflow-y: hidden;
}

.btn-modal {
    padding: 10px 20px;
    display: block;
    margin: 100px auto 0;
    font-size: 18px;
}

.modal,
.overlay {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    z-index: -10;
}

.modal {
    opacity: 0;

    &.show,
    &.mobile {
        z-index: 99999999999;
        opacity: 1;
    }
}

.overlay {
    background: rgba(188, 183, 179, 0.1);
    backdrop-filter: blur(5px);
    cursor: pointer;
}

.modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #FDFAFA;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 10000;

    @media (max-width: 850px) {
        top: 57% !important;
    }
}

@media (max-width: 1250px) {
    .modal-content {
        top: 50%;
    }
}

.login-pupup {
    background-color: pink;
    width: 72rem;
    height: fit-content;

}