.questions {
    height: calc(100vh + 54px);
    padding: 0 1rem 1rem 1rem;

    @media (max-width: 850px){
        height: 840px !important;
    }

    &__wrapper {
        width: 100%;
        height: 100%;
        min-height: 550px;
        display: flex;
        justify-content: center;
        border-radius: 30px;
        background-image: url('./../images/questions/questions-background.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        @media (max-width: 850px){
            align-items: center;
        }
    }

    &__box {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 20vh;

        @media (max-width: 850px){
            margin-top: 0;
            justify-content: center;
        }

        &__title {
            font-family: 'Roboto', sans-serif;
            font-weight: 700;
            font-size: 8.2rem;
            letter-spacing: -0.02em;
            line-height: 9.84rem;
            letter-spacing: -0.02em;
            color: $white;
        }

        &__text {
            font-family: 'Roboto', sans-serif;
            font-weight: 400;
            font-size: 4.62rem;
            line-height: 120%;
            letter-spacing: -0.021em;
            color: $white;
        }

        &__button {
            margin: 3.42rem 0 2.56rem;
            align-self: center;
            display: flex;
            align-items: center;
            border-radius: 50px;
            background-color: $white;
            border: none;
            cursor: pointer;

            &__text {
                font-weight: 400;
                font-size: 1.54rem;
                line-height: 2.05rem;
                text-transform: uppercase;
                color: $black;
                padding: 1rem 1.36rem 1rem 0.7rem;
            }

            &__img {
                padding: 0.34rem 0 0.34rem 0.34rem;
            }
        }

        &__img {
            margin-bottom: 30%;

            @media screen and (max-width: 850px) {
                width: 175px;
            }
        }

    }
}

@media (max-width: 850px) {
    .questions__wrapper {
        background-size: auto;
        min-height: auto;
    }

    .questions__box__button {
        width: auto;
    }

    .questions__box__title {
        font-size: 48px;
        width: 80%;
        line-height: 58px;
        text-align: center;
    }

    .questions__box__button__text {
        font-size: 16px;
        line-height: 19px;
        padding: 6px 16px 6px 8px;
    }

    .questions__box__button__img {
        padding: 0 0 0 4px;
        width: 24px;
        height: 24px;
    }
}



@media (max-height: 600px) {
    .mainPage__first__icons {
        height: 260px;
    }
}