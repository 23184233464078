.services {
    display: flex;
    flex-direction: column;

    &__expert-margin {
        margin: 4.3rem;
    }

    &__mobile__mylist {
        display: none;
        flex-direction: column;
        margin: 18px 0;

        &__el {
            &:first-child {
                border: none;
            }

            &__body.done {
                margin-left: 0px !important;
            }

            &.false>&__body {
                display: none;
            }

            &.true>&__body {
                display: block;
            }

            &.false>&__header>&__header__edit {
                display: none;
            }

            &.true>&__header>&__header__edit {
                display: block;
            }

            padding: 12px 0;
            border-top: 1px solid #E4E3E2;
            width: 100%;

            &__header {
                display: flex;
                align-items: center;

                &__info {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                }

                &__edit {
                    height: 24px;
                    width: 24px;
                    margin-right: 12px;
                }

                &__name {
                    font-family: 'Roboto';
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 19px;
                    color: #0B0A0A;
                    align-self: center;
                }

                &__time {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    text-transform: uppercase;
                    color: #0B0A0A;
                    padding: 6px 16px;
                    background: #FDFAFA;
                    box-shadow: 0px 0px 15px rgba(79, 79, 79, 0.11);
                    border-radius: 50px;
                    border: none;
                    white-space: nowrap;

                    &.grey {
                        color: #BCB7B3;
                    }
                }
            }

            &__body {
                margin: 16px 0 0 32px;

                &>.subscribeServicesList__modal__info__list__el {
                    margin-bottom: 12px;
                    align-items: center;
                }
            }
        }
    }

    &__list {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin: 2.56rem 0;

        &__title {
            width: 100%;
            display: grid;
            grid-template-columns: 204fr 300fr 153fr 205fr 200fr 200fr;
            gap: 2.56rem;
            text-align: center;
            font-weight: 400;
            font-size: 1.71rem;
            font-family: 'Roboto', sans-serif;
            line-height: 2.1rem;
            text-align: center;
            text-transform: uppercase;
            color: #56514D;

            padding-bottom: 1.3rem;
            border-bottom: 1px solid #E4E3E2;

            &__el {
                &__date {
                    text-align: center;
                }

                &__serv {
                    text-align: center;
                }

                &__time {
                    text-align: center;
                }

                &__name {
                    text-align: center;
                }

                &__status {
                    text-align: center;
                }

            }
        }

        &__item {
            position: relative;
            width: 100%;
            display: grid;
            grid-template-columns: 204fr 300fr 153fr 205fr 200fr 200fr;
            gap: 2.56rem;
            align-items: center;
            cursor: pointer;

            font-weight: 400;
            font-size: 2.1rem;
            line-height: 2.45rem;
            color: $black;

            border-top: 1px solid #E4E3E2;
            padding: 1.3rem 0;

            &.done {
                color: #BCB7B3;
                cursor: auto;

                &:hover>.services__list__item__edit {
                    transform: scale(0);
                }

                &:hover>.services__list__item__date {
                    margin-left: 0px;
                }
            }

            &.expert {

                &:hover>.services__list__item__edit {
                    transform: scale(0);
                }

                &:hover>.services__list__item__date {
                    margin-left: 0px;
                }
            }

            &:first-child {
                border: none;
            }

            &__edit {
                position: absolute;
                display: block;
                cursor: pointer;
                transform: scale(0);
                height: 3.5rem;
                width: 3.5rem;
            }

            &:hover>&__edit {
                transform: scale(1);
                transition: 700ms;
            }

            &:hover>&__date {
                margin-left: 2.56rem;
            }

            &__date {
                transition: margin 700ms;
                background: #FDFAFA;
                box-shadow: 0px 0px 1.3rem rgba(79, 79, 79, 0.08);
                border-radius: 50px;
                padding: 0.5rem 3rem;
                text-transform: uppercase;
                letter-spacing: -0.023em;
                white-space: nowrap;
                justify-self: flex-start;

                &.grey {
                    color: #BCB7B3;
                }
            }

            &__title {
                text-align: center;
                font-family: 'Roboto', sans-serif;
            }

            &__time {
                font-family: 'Roboto', sans-serif;
                text-align: center;
            }

            &__name {
                font-family: 'Roboto', sans-serif;
                text-align: center;
            }

            &__location {
                font-family: 'Roboto', sans-serif;
                text-align: center;
            }

            &__status {
                font-weight: 400;
                font-size: 1.71rem;
                line-height: 2.1rem;
                text-align: center;
                font-family: 'Roboto', sans-serif;

                &.paid {
                    color: #14AE5C;
                }

            }

        }

        &__more {
            display: flex;
            background: none;
            border: none;
            align-items: center;
            cursor: pointer;

            &.myservices {
                margin-top: 2.56rem;
            }

            &.list {
                align-self: center;
            }

            &__img {
                margin-right: 0.85rem;
                height: 1.71rem;
                width: 1.71rem;

                @media (max-width: 850px) {
                    height: 20px;
                    width: 20px;
                }
            }

            &.hide {
                display: none;
            }

            &__text {
                font-weight: 400;
                font-size: 1.71rem;
                font-family: 'Roboto', sans-serif;
                line-height: 2.1rem;
                color: #56514D;

                @media (max-width: 850px) {
                    font-size: 14px;
                }
            }

            &.hide {
                display: none;
            }

            @media (max-width: 850px) {
                margin-left: auto;
                margin-right: auto;
            }
        }
    }

    &__text {
        text-align: center;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        font-size: 4.62rem;
        line-height: 120%;
        letter-spacing: -0.023em;
        color: #BCB7B3;
        margin: 4.3rem 0;
    }

    &__title {
        margin-left: 2.56rem;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        font-size: 4.62rem;
        line-height: 120%;
        letter-spacing: -0.023em;
        color: $black;
        margin-bottom: 2.56rem;
    }

    &__filter {
        width: 100%;
        display: flex;
        gap: 2.56rem;

        &__mobile {
            display: none;
            margin-left: 1.35rem;

            &__input {
                display: none;
            }

            &__img {
                align-self: center;
                display: none;
            }

            &__list {
                width: 100vw;
                height: 100vh;
                background-color: $white;
                display: flex;
                flex-direction: column;
                padding: 0 1.45rem;
                justify-content: flex-start;
                overflow-y: auto;
                overflow-x: hidden;
                top: 50% !important;

                &__modal {
                    z-index: 10000000;
                }

                &__header {
                    display: flex;
                    justify-content: space-between;
                    margin: 3.42rem 0 1.71rem;
                    width: 100%;

                    &__title {
                        font-size: 2.4rem;
                        line-height: 120%;
                        letter-spacing: -0.023em;
                        color: #0B0A0A;
                        font-weight: 400;
                        font-family: 'Roboto';

                        @media (max-width: 850px) {
                            font-size: 27px;
                        }
                    }
                }

                &__filters {
                    display: flex;
                    flex-direction: column;
                    width: 100%;

                    &__el {
                        margin-bottom: 2.1rem;

                        input[type="checkbox"]:checked,
                        input[type="checkbox"]:not(:checked) {
                            position: absolute;
                            z-index: -1;
                            opacity: 0;
                        }

                        input[type="checkbox"]:checked+label,
                        input[type="checkbox"]:not(:checked)+label {
                            display: inline-flex;
                            align-items: center;
                            user-select: none;
                        }

                        input[type="checkbox"]:not(:checked)+label::before {
                            content: '';
                            display: inline-block;
                            width: 1.71rem;
                            height: 1.71rem;
                            flex-shrink: 0;
                            flex-grow: 0;
                            border: 1px solid #BCB7B3;
                            border-radius: 3px;
                            margin-right: 0.7rem;
                            background-repeat: no-repeat;
                            background-position: center center;
                            background-size: 1.5rem;
                            transition: all .3s;
                        }

                        input[type="checkbox"]:hover+label::before {
                            border: 1px solid #BCB7B3;
                            border-radius: 3px;
                            background-image: url('./../images/profile/checkbox-hover.svg');
                            background-size: 1.5rem;
                            background-position: center center;
                        }

                        input[type="checkbox"]:checked+label::before {
                            content: '';
                            display: inline-block;
                            width: 1.71rem;
                            height: 1.71rem;
                            flex-shrink: 0;
                            flex-grow: 0;
                            border-radius: 3px;
                            margin-right: 0.7rem;
                            background-repeat: no-repeat;
                            background-position: center center;
                            border: 1px solid #E75549;
                            border-radius: 3px;
                            background-image: url('./../images/profile/checkbox-checked.svg');
                            background-size: 1.5rem;
                        }

                        &__body {

                            &__calendar {
                                transform: scale(0.7) translateY(-75px) translateX(-75px) !important;

                                @media (max-width: 850px) {
                                    transform: scale(1) !important;
                                }
                            }

                            &__box {
                                display: flex;
                                width: 70%;
                            }
                        }

                        &__header {
                            display: flex;
                            margin-bottom: 1.35rem;

                            @media (max-width: 850px) {
                                align-items: center;
                            }

                            &__title {
                                font-size: 1.35rem;
                                line-height: 1.8rem;
                                color: #56514D;
                                font-family: 'Roboto';
                                margin-right: 0.5rem;

                                @media (max-width: 850px) {
                                    font-size: 15px;
                                }
                            }

                            &__img {
                                @media (max-width: 850px) {
                                    height: 15px;
                                    width: 15px;
                                }
                            }
                        }

                    }

                }

                &__button {
                    margin-top: 20px;
                    background: #E75549;
                    border-radius: 50px;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 21px;
                    text-transform: uppercase;
                    color: #FDFAFA;
                    padding: 6px 16px 6px 36px;
                    border: none;
                    margin-bottom: 100px;
                    background-image: url('./../images/profile/mobile-filter-arrow.svg');
                    background-repeat: no-repeat;
                    background-position: 0.35rem;
                    background-size: 24px;
                }

            }

            &__input:checked~&__list {
                transform: translateX(0vw);
            }
        }

        &__title__mobile {
            display: none;
            font-size: 28px;
            line-height: 120%;
            font-family: 'Roboto';
            font-weight: 400;
        }

        &__search-box {
            width: 100%;
            position: relative;

            &__search {
                width: 100%;
                border: 1px solid #BCB7B3;
                border-radius: 2.56rem;
                font-weight: 400;
                font-size: 1.71rem;
                font-family: 'Roboto', sans-serif;
                line-height: 2.1rem;
                padding: 0.9rem;
                padding-left: 4.3rem;

                &::placeholder {
                    color: #BCB7B3;
                }

                &:focus {
                    outline: none;
                    border-color: $black;

                    &::placeholder {
                        color: white;
                    }
                }
            }

            &__search:focus~&__img {
                background-image: url('./../images/profile/search-black.svg');
            }

            &__img {
                position: absolute;
                left: 1.71rem;
                top: calc(2.1rem - 10px);
                height: 20px;
                width: 20px;
                background-image: url('./../images/profile/search.svg');
            }
        }

        &__price-box,
        &__duration-box,
        &__location-box {
            position: relative;
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;

            &.filter {
                @media (max-width: 1000px) {
                    display: none;
                }
            }

            @media (max-width: 850px) {
                display: none;
            }

            &.filter.mobile {
                display: none;

                @media (max-width: 1000px) {
                    display: block !important;
                }
            }

            &__checkbox {
                display: none;
            }

            &__checkbox:checked~&__content {
                transform: scaley(1);
                z-index: 100;
            }

            &__content {
                transform: scaley(0);
                transform-origin: left top;
                transition: 0.1s;
                position: absolute;
                display: flex;

                &.alphabet {

                    @media screen and (max-width: 1200px) {
                        right: auto !important;
                    }

                    @media screen and (max-width: 1000px) {
                        left: 0 !important;
                    }
                }

                &.filter {
                    @media (max-width: 1000px) {
                        left: 0 !important;
                    }
                }

                input[type="checkbox"]:checked,
                input[type="checkbox"]:not(:checked) {
                    position: absolute;
                    z-index: -1;
                    opacity: 0;
                }

                input[type="checkbox"]:checked+label,
                input[type="checkbox"]:not(:checked)+label {
                    display: inline-flex;
                    align-items: center;
                    user-select: none;
                }

                input[type="checkbox"]:not(:checked)+label::before {
                    content: '';
                    display: inline-block;
                    flex-shrink: 0;
                    flex-grow: 0;
                    border: 1px solid #BCB7B3;
                    border-radius: 3px;
                    margin-right: 0.7rem;
                    background-repeat: no-repeat;
                    background-position: center center;
                    width: 20px;
                    height: 20px;
                    background-size: 16px;
                }

                input[type="checkbox"]:hover+label::before {
                    border: 1px solid #BCB7B3;
                    border-radius: 3px;
                    background-image: url('./../images/profile/checkbox-hover.svg');
                    background-size: 16px;
                    background-position: center center;
                }

                input[type="checkbox"]:checked+label::before {
                    content: '';
                    display: inline-block;
                    width: 20px;
                    height: 20px;
                    background-size: 16px;
                    flex-shrink: 0;
                    flex-grow: 0;
                    border-radius: 3px;
                    margin-right: 0.7rem;
                    background-repeat: no-repeat;
                    background-position: center center;
                    border: 1px solid #E75549;
                    border-radius: 3px;
                    background-image: url('./../images/profile/checkbox-checked.svg');
                    background-size: 16px;
                }
            }

            &__checkbox:checked~&__title>&__title__text {
                color: $black;
                background-image: url('./../images/profile/arrow-up.svg');
            }

            &__checkbox:checked~&__title {
                border-color: $black;
            }

            &__title {
                display: flex;
                cursor: pointer;
                border: 1px solid #BCB7B3;
                border-radius: 2.56rem;

                &.filter {
                    max-width: 235px;
                }


                &__text {
                    font-weight: 400;
                    font-size: 1.71rem;
                    font-family: 'Roboto', sans-serif;
                    line-height: 2.1rem;
                    color: #BCB7B3;
                    padding: 1rem 4.1rem 1rem 1.71rem;

                    &.filter {
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }

                    background-image: url('./../images/profile/arrow-down.svg');
                    background-repeat: no-repeat;
                    background-position: calc(100% - 1rem);
                }

                &__img {
                    padding-right: 1rem;
                }
            }
        }

        &__price-box__content {
            top: 5.15rem;
            right: -4.62rem;
            height: 6.85rem;
            background: $white;
            border-radius: 8px;
            align-items: center;
            justify-content: space-around;
            cursor: auto;
            padding: 1.71rem;

            &.calendar {
                right: -11.55rem;
                background: none;
            }

            &__second {
                margin-left: 0.7rem;
            }

            &__first,
            &__second {
                display: flex;
                align-items: center;
                font-weight: 400;
                font-size: 1.71rem;
                font-family: 'Roboto', sans-serif;
                line-height: 2.1rem;
                color: black;

                @media (max-width: 850px) {
                    font-size: 15px;
                    margin-right: 60px;
                }

                &__input {
                    text-align: center;
                    color: $black;
                    width: 6rem;
                    height: 3.42rem;
                    margin-left: 0.7rem;
                    border: 1px solid #BCB7B3;
                    border-radius: 4px;
                    font-weight: 400;
                    font-size: 1.71rem;
                    font-family: 'Roboto', sans-serif;
                    line-height: 2.1rem;
                    color: $black;

                    @media (max-width: 850px) {
                        width: 70px;
                        height: 35px;
                        font-size: 15px;
                    }

                    &::placeholder {
                        color: #BCB7B3;
                    }

                    &:focus {
                        outline: none;
                    }

                    &::-webkit-outer-spin-button,
                    &::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                    }
                }


            }
        }

        &__location-box__content {
            width: 21.4rem;
            flex-direction: column;
            top: 5.15rem;
            padding: 1.71rem;
            background: $white;
            border-radius: 8px;
            justify-content: space-around;
            cursor: auto;

            &__el {
                display: flex;
                margin-top: 1rem;

                &:first-child {
                    margin-top: 0;
                }

                &__input {
                    cursor: pointer;
                    width: 1.71rem;
                    height: 1.71rem;
                }

                &__text {
                    font-weight: 400;
                    font-size: 1.71rem;
                    font-family: 'Roboto', sans-serif;
                    line-height: 2.1rem;
                    color: $black;
                    margin-left: 0.7rem;
                    cursor: pointer;

                    @media (max-width: 850px) {
                        font-size: 15px;
                    }
                }
            }
        }

        &__duration-box__content {
            flex-direction: column;
            padding: 1.71rem;
            top: 5.15rem;
            background: $white;
            border-radius: 8px;
            justify-content: space-around;
            cursor: auto;

            &.top {
                width: 30rem;
            }

            &__el {
                display: flex;
                margin-top: 1rem;

                &:first-child {
                    margin-top: 0;
                }

                &__input {
                    cursor: pointer;
                    width: 1.71rem;
                    height: 1.71rem;
                }

                &__text {
                    font-weight: 400;
                    font-size: 1.71rem;
                    font-family: 'Roboto', sans-serif;
                    line-height: 2.1rem;
                    color: $black;
                    margin-left: 0.7rem;
                    cursor: pointer;

                    @media (max-width: 850px) {
                        font-size: 15px;
                    }
                }
            }
        }
    }
}

@media (max-width: 850px) {
    .services__filter__mobile__img {
        display: block;
    }

    .services__filter__price-box__title,
    .services__filter__duration-box__title,
    .services__filter__location-box__title {
        display: none;
    }

    .services__list {
        display: none;
    }

    .services__filter__title__mobile {
        display: block;
        align-self: center;
        margin: 20px 0;
    }


    .services__mobile__mylist {
        display: flex;
    }

    .services__filter__search-box {
        width: 100%;
    }

    .services__filter__search-box__search {
        font-size: 16px;
        line-height: 19px;
        padding: 11px 45px;
    }
}

@media (max-width: 850px) {
    .services__filter__mobile__list__filters__el__body__calendar {
        height: 370px;
    }

    .services {
        padding: 0 16px;
    }

    .services__text {
        font-size: 28px;
        line-height: 120%;
    }

    .services__filter__search-box__img {
        top: 11px;
    }

    .services__title {
        align-self: center;
        font-size: 28px;
        line-height: 120%;
        margin: 20px 0;
    }

    .services__filter__search-box.first {
        margin-top: 0;
    }

    .services__list__more.list {
        margin-top: 1px;
        margin: 0 auto;
    }

}