.queries {
    display: flex;
    flex-direction: column;

    &__ask {
        display: flex;
        flex-direction: column;
        margin-top: 4.3rem;

        &__textarea {
            font-family: 'Roboto';
            height: 17.1rem;
            width: 100%;
            resize: none;
            border: 1px solid #BCB7B3;
            border-radius: 30px;
            font-weight: 400;
            font-size: 1.71rem;
            line-height: 2rem;
            padding: 1.71rem 2.56rem;
            color: $black;

            &::placeholder {
                color: #BCB7B3;
            }

            &:focus {
                outline: none;
                border-color: $black;
            }
        }

        &__submit {
            align-self: flex-start;
            padding: 1rem 1.36rem 0.77rem 4.45rem;
            margin-top: 30px;
            background: #E75549;
            border-radius: 50px;
            border: none;
            font-weight: 400;
            letter-spacing: -0.03em;
            font-size: 1.54rem;
            line-height: 2.4rem;
            letter-spacing: -0.03em;
            text-transform: uppercase;
            color: $white;
            background-image: url('./../images/profile/buy-arrow.svg');
            background-repeat: no-repeat;
            background-position: 0.35rem;
            background-size: 3.43rem;
            cursor: pointer;
        }
    }

    &__list {
        display: flex;
        flex-direction: column;
        margin: 4.3rem 0 8.6rem;
        min-height: 30rem;

        &__title {
            font-size: 3rem;
            line-height: 3.25rem;
            color: $black;
            font-family: 'Roboto';
        }

        &__text {
            font-family: 'Roboto', sans-serif;
            font-weight: 400;
            align-self: center;
            justify-self: center;
            font-size: 4.62rem;
            line-height: 120%;
            letter-spacing: -0.021em;
            color: #BCB7B3;
            margin: auto 0;
        }

        &__box {
            display: flex;
            flex-direction: column;
            margin: 2.56rem 0 6rem;

            @media (max-width: 850px) {
                width: 100%;
            }

            &__el {
                width: 100%;
                padding: 1.3rem 0;
                border-bottom: 1px solid #E4E3E2;

                &__wrapper {
                    background-image: url('./../images/profile/queries-question.svg');
                    background-repeat: no-repeat;
                    background-position: top left;
                    padding-left: 5.3rem;
                    width: 100%;
                    background-size: 4.3rem;
                }

                &__text {
                    font-family: 'Roboto';
                    font-weight: 400;
                    font-size: 1.71rem;
                    line-height: 2rem;
                    word-wrap: break-word;
                }

                &__info {
                    display: flex;
                    margin-top: 1.3rem;

                    &__date {
                        font-size: 1.36rem;
                        line-height: 1.62rem;
                        color: #BCB7B3;
                        font-family: 'Roboto', sans-serif;
                        font-weight: 400;
                    }

                    &__status {
                        font-size: 1.36rem;
                        line-height: 1.62rem;
                        margin-left: 0.4rem;
                        font-family: 'Roboto', sans-serif;
                        font-weight: 400;

                        &.done {
                            color: #14AE5C;
                        }

                        &.inprogress {
                            color: #F29270;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 850px) {
    .queries {
        padding: 0 16px;

        &__list__text {
            font-size: 28px;
            line-height: 120%;
            text-align: center;
        }

        &__ask {
            margin-top: 22px;

            &__textarea {
                height: 150px;
                padding: 10px 16px;
                font-size: 16px;
                line-height: 19px;
                border-radius: 15px;
            }

            &__submit {
                align-self: center;
                margin-top: 24px;
                font-size: 14px;
                line-height: 21px;
                padding: 6px 16px 6px 36px;
                background-size: 24px;
            }
        }


        &__list {
            margin-top: 40px;
            align-items: center;

            &__title {
                font-weight: 400;
                font-size: 20px;
                line-height: 120%;
            }
        }
    }
}