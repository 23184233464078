.dashboard__popup {
    display: flex;
    flex-direction: column;

    &.scroll {
        overflow-y: scroll;
    }

    &__box {
        margin-top: 2.56rem;
        display: flex;
        flex-direction: column;

        &__button {
            cursor: pointer;
            margin-top: 1.28rem;
            display: flex;
            align-items: center;
            gap: 6px;

            &__img {
                height: 1.71rem;
                width: 1.71rem;

                @media screen and (max-width: 850px) {
                    width: 20px;
                    height: 20px;
                }
            }

            &__text {
                font-size: 1.71rem;
                line-height: 2rem;
                color: #BCB7B3;
                font-family: 'Roboto', sans-serif;

                @media screen and (max-width: 850px) {
                    font-size: 16px;
                    line-height: 19px;
                }
            }
        }

        &__title {
            font-weight: 400;
            font-size: 1.71rem;
            line-height: 2rem;
            color: #0B0A0A;
            font-family: 'Roboto', sans-serif;

            @media screen and (max-width: 850px) {
                font-size: 16px;
                line-height: 19px;
            }
        }

        &__el {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 1.28rem 0;
            border-bottom: 1px solid #BCB7B3;

            &__input {
                border: none;
                width: 100%;
                margin-right: 1rem;
                font-size: 1.71rem;
                color: #56514D;
                background-color: inherit;
                overflow-y: hidden;
                font-family: 'Roboto', sans-serif;

                &:focus {
                    outline: none;
                }

                @media screen and (max-width: 850px) {
                    font-size: 16px;
                    line-height: 19px;
                }
            }

            &__img {
                cursor: pointer;
                width: 1.71rem;
                height: 1.71rem;
            }
        }
    }

    &__inputs {
        display: flex;
        flex-direction: column;
        gap: 1.28rem;
        width: 34.2rem;

        &__el-img {
            max-height: 18.15rem;
            align-self: center;
        }

        &__el {
            display: flex;
            gap: 0.7rem;
            align-items: center;

            &__input {
                width: 100%;
                border: none;
                background-color: inherit;
                font-family: 'Roboto', sans-serif;

                font-size: 1.71rem;
                color: $black;

                @media screen and (max-width: 850px) {
                    font-size: 16px;
                    line-height: 19px;
                }

                &__container {
                    display: flex;
                    align-items: center;
                    font-size: 1.71rem;
                    color: #0B0A0A;

                    &.price {
                        align-items: flex-end;
                    }
                }

                &::placeholder {
                    color: #BCB7B3;

                }

                &:focus {
                    outline: none;
                }
            }

            &__img {
                width: 1.71rem;

                @media screen and (max-width: 850px) {
                    width: 20px;
                    height: 20px;
                }
            }

            &__img-box {
                border: 4px dashed #BCB7B3;
                height: 17.1rem;
                position: relative;
                display: flex;
                flex-direction: column;
                gap: 1rem;
                align-items: center;
                justify-content: center;
                border-radius: 15px;
                overflow: hidden;

                &.full {
                    border: none;
                }

                &__img {
                    position: absolute;
                    height: 100%;
                    width: 100%;
                    opacity: 0.4;
                    object-fit: cover;
                    z-index: -1;

                    &.full {
                        opacity: 1;
                    }
                }

                &__text {
                    font-family: 'Roboto', sans-serif;
                    white-space: pre;
                    font-size: 1.71rem;
                    line-height: 2rem;
                    text-align: center;
                    color: #56514D;
                }

                &__input {
                    display: none;
                }

                &__button {
                    display: flex;
                    background-color: inherit;
                    align-self: center;
                    border: none;
                    cursor: pointer;
                    gap: 0.7rem;

                    &__img {
                        height: 2.5rem;
                        width: 2.5rem;
                    }

                    &__text {
                        font-size: 1.54rem;
                        line-height: 2.4rem;
                        letter-spacing: -0.03em;
                        text-transform: uppercase;
                        color: #56514D;
                    }
                }
            }
        }
    }

    &__language {
        background: #E4E3E2;
        border-radius: 30px;
        width: 43rem;

        &__el {
            font-size: 1.71rem;
            line-height: 2rem;
            color: #56514D;
            font-family: 'Roboto', sans-serif;

            &.active {
                font-size: 1.71rem;
                line-height: 2rem;
                color: #FDFAFA;
            }
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;

        @media screen and (max-width: 850px) {
            width: calc(100vw - 40px);
            top: 50% !important;
        }

        &.scroll {
            top: 68.5rem;
        }

        &__wrapper {
            padding: 2.56rem 5.15rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 2.56rem;
        }

        &__header {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 1.28rem;

            &__img {
                height: 10.25rem;
                width: 10.25rem;

                @media screen and (max-width: 850px) {
                    width: 80px;
                    height: 80px;
                }
            }

            &__text {
                font-size: 3rem;
                line-height: 4.2rem;
                color: #0B0A0A;
                font-family: 'Roboto', sans-serif;

                &.green {
                    color: #14AE5C;
                }

                @media screen and (max-width: 850px) {
                    font-size: 22px;
                    line-height: 26px;
                }
            }
        }

        &__info {
            display: flex;
            flex-direction: column;
            gap: 2.56rem;

            &__text {
                font-size: 1.71rem;
                line-height: 2rem;
                text-align: center;
                color: #56514D;
                white-space: pre;
                font-family: 'Roboto', sans-serif;

                @media screen and (max-width: 850px) {
                    font-size: 16px;
                    line-height: 19px;
                }
            }
        }

        &__buttons {
            display: flex;
            flex-direction: column;
            gap: 1.28rem;
            align-items: center;

            &__button {
                cursor: pointer;
                display: flex;
                align-items: center;
                border: none;
                border-radius: 50px;

                &__img {
                    padding: 0.35rem;
                    height: 3.42rem;
                    width: 3.42rem;
                }

                &__text {
                    padding: 0 1.36rem 0 0.35rem;
                    font-size: 1.54rem;
                    line-height: 2.4rem;
                    letter-spacing: -0.03em;
                    text-transform: uppercase;
                    color: #FDFAFA;
                    font-family: 'Roboto', sans-serif;

                    @media screen and (max-width: 850px) {
                        font-size: 14px;
                        line-height: 21px;
                    }
                }

                &.keep {
                    background: #E75549;
                }

                &.delete {
                    background: #0B0A0A;
                }
            }

            &__button-close {
                cursor: pointer;
                display: flex;
                gap: 6px;
                background-color: inherit;
                align-items: center;
                border: none;

                &__text {
                    font-size: 1.71rem;
                    line-height: 2rem;
                    color: #BCB7B3;
                    font-family: 'Roboto', sans-serif;

                    @media screen and (max-width: 850px) {
                        font-size: 16px;
                        line-height: 19px;
                    }
                }
            }
        }
    }
}

.container {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    background: #E4E3E2;
    overflow: hidden;
    max-width: 34.2rem;
    align-self: center;
}

.tabs {
    display: flex;
    position: relative;
    background-color: inherit;
    border-radius: 36px;
    width: 100%;

    * {
        z-index: 2;
    }
}

input[type="radio"] {
    display: none;
}

.tab {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3.42rem;
    width: 50%;
    font-size: 1.71rem;
    line-height: 2rem;
    color: #56514D;
    border-radius: 99px;
    cursor: pointer;
    transition: color 0.15s ease-in;
    font-family: 'Roboto', sans-serif;

    &.white {
        color: #FDFAFA;
    }
}

.notification {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;
    margin-left: 0.75rem;
    border-radius: 50%;
    background-color: #e6eef9;
    transition: 0.15s ease-in;
}

.glider {
    position: absolute;
    display: flex;
    height: 3.42rem;
    width: 50%;
    background-color: #E75549;
    z-index: 1;
    border-radius: 99px;
    transition: 0.25s ease-out;

    &.first {
        transform: translateX(0);
    }

    &.second {
        transform: translateX(100%);
    }
}
