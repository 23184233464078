.react-calendar {
    background: #FDFAFA;
    box-shadow: 0px 18px 32px rgba(79, 79, 79, 0.05);
    border-radius: 16px;
    border: none !important;
    padding: 25px;
    align-self: flex-start;

    &__navigation {
        border-bottom: 0.8px solid #BCB7B3;

        &__arrow,
        &__label {
            background: none !important;
        }

        &__prev2-button,
        &__next2-button {
            display: none;
        }

        &__label__labelText {
            font-weight: 400;
            font-size: 20px;
            font-family: 'Roboto', sans-serif;
            line-height: 24px;
            display: flex;
            align-items: center;
            color: $black;
            text-transform: uppercase;
            display: block;
        }

        &__arrow-right,
        &__arrow-left {
            height: 36px;
            width: 36px;
            padding: 5px;
            border: 1px solid white;
            border-radius: 40px;
        }

        &__arrow-right:hover,
        &__arrow-left:hover {
            border: 1px solid $black;
        }
    }

    &__month-view {
        &__days__day {

            &>abbr {
                font-weight: 600;
                font-size: 13px;
                line-height: 16px;
                color: $black;
            }

            &--weekend>abbr {
                color: $black;
            }

            &--neighboringMonth>abbr {
                color: #BCB7B3 !important;
            }
        }

        &__weekdays__weekday>abbr {
            text-decoration: none;
            font-size: 14px;
            line-height: 11px;
            text-align: center;
            text-transform: uppercase;
            color: #56514D;
        }
    }

    &__tile {
        padding: 0 !important;
        margin: 8px 0 !important;
        height: 24px;

        &:hover {
            background: none !important;
        }

        &:hover>abbr {
            border: 1px solid #BCB7B3;
        }

        &>abbr {
            display: block;
            width: 24px;
            height: 24px;
            margin: auto;
            padding: 3px;
            border: 1px solid white;
            border-radius: 40px;
        }

        &--active {
            background: none !important;

            &>abbr {
                border: 1px solid #0B0A0A !important;
            }

        }

        &--now {
            background: none !important;

            &>abbr {
                color: #E75549;
            }
        }
    }
}

.react-calendar__tile:enabled:hover, .react-calendar__tile:enabled:focus {
    background-color: inherit !important;
}

@media (max-width: 1200px) {
    .react-calendar {
        // transform: scale(0.7) translateY(-100px);
    }
}

@media (max-width: 600px) {
    .react-calendar {
        transform: none;
    }

    .react-calendar__navigation__label__labelText { 
        font-size: 16px;
    }
}