.account {
    display: flex;
    margin-top: 17.12rem;

    &__wrap {
        display: flex;
        flex-direction: column;
    }

    &__container {
        display: flex;
    }

    &__photo {
        display: flex;
        flex-direction: column;

        &__img {
            height: 17.12rem;
            width: 17.12rem;
            border-radius: 100%;
            object-fit: cover;
            cursor: pointer;
        }

        &__button {
            align-self: center;
            width: auto;
            height: 2.56rem;
            margin-top: 1.54rem;
            display: flex;
            align-items: center;
            background-color: white;
            border: none;
            cursor: pointer;

            &__text {
                margin-left: 0.8rem;
                font-size: 1.54rem;
                line-height: 2rem;
                color: #56514D;
            }

            &__img {
                height: 2.5rem;
                width: 2.5rem;
            }
        }
    }

    &__info {
        display: flex;
        flex-direction: column;
        margin-left: 8.56rem;

        &__title {
            font-size: 3rem;
            line-height: 4.1rem;
            margin-bottom: 4.3rem;
            font-weight: 400;
        }

        &__box {
            width: 74.5rem;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            &__item {
                width: 36rem;
                display: flex;
                align-items: center;
                margin-bottom: 1.3rem;

                &__tgIcon {
                    width: 2.73rem;
                    height: 2.73rem;

                    @media screen and (max-width: 850px) {
                        width: 32px;
                        height: 32px;
                    }
                }

                &__tgText {
                    margin-left: .8rem;
                    font-family: 'Roboto', sans-serif;
                    font-size: 1.71233rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;

                    @media screen and (max-width: 850px) {
                        margin-left: 8px;
                    }
                }

                &__tgButton {
                    &:hover {
                        cursor: pointer;
                    }
                }

                &__input {
                    width: 33.1rem;
                    height: 3.85rem;
                    border: 1px solid #BCB7B3;
                    border-radius: 30px;
                    margin-left: 0.8rem;
                    font-size: 1.71rem;
                    font-family: 'Roboto', sans-serif;
                    line-height: 2rem;
                    padding: 0.9rem 1.71rem;

                    @media (max-width: 850px) {
                        margin-left: 8px;
                    }


                    &.error {
                        border-color: #E75549;
                        color: #E75549;
                    }

                    &:focus {
                        outline: none;
                        border-color: $black;
                        color: $black;


                        &::placeholder {
                            color: white;
                        }
                    }

                    &::placeholder {
                        color: #BCB7B3;
                    }
                }
            }
        }
    }

    &__buttons {
        margin-bottom: 4.3rem;

        &__subs,
        &__serv,
        &__quer {
            border-radius: 30px;
            padding: 0.35rem 1.71rem;
            margin-left: 2.56rem;
            background-color: white;
            border: none;
            font-size: 3.42rem;
            line-height: 4.1rem;
            color: #56514D;
            cursor: pointer;
            margin-top: 10.2rem;
            padding-bottom: 0.9rem;
        }
    }

    &__modal {

        &__wrapper {
            padding: 3rem 5.15rem;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        &__content {
            background-color: $white;
            box-shadow: 0px 2rem 4.1rem rgba(79, 79, 79, 0.1);
            border-radius: 30px;

            &__mobile {
                display: none;
                max-width: 90%;
                max-height: 17.1rem;
            }

            &__box {
                position: relative;
                width: 55.1rem;
                height: 27.7rem;
                border: 4px dashed #BCB7B3;
                border-radius: 15px;

                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                margin-bottom: 2.56rem;

                &__img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    margin-left: auto;
                    margin-right: auto;
                    height: 100%;
                    opacity: 0.2;
                    z-index: -1;
                }



                &__text {
                    font-size: 1.71rem;
                    font-family: 'Roboto', sans-serif;
                    line-height: 2rem;
                    text-align: center;
                    color: #BCB7B3;
                    margin-bottom: 1.3rem;
                    white-space: pre-line;
                }

                &__button {
                    display: flex;
                    align-items: center;
                    border: none;
                    cursor: pointer;

                    &__text {
                        margin-left: 0.8rem;
                        font-size: 1.54rem;
                        line-height: 2rem;
                        text-transform: uppercase;
                        color: #BCB7B3;
                        white-space: pre-line;
                    }

                    &__img {
                        height: 2.5rem;
                        width: 2.5rem;
                    }
                }
            }

            &__button {
                display: flex;
                align-items: center;
                background-color: #E75549;
                border-radius: 50px;
                border: none;

                font-size: 1.54rem;
                line-height: 2rem;
                text-transform: uppercase;
                color: $black;
                cursor: pointer;

                &__text {
                    padding: 1rem 1.4rem 1rem 0.3rem;
                    color: $white;
                }

                &__img {
                    margin: 0 0.3rem;
                    height: 3.42rem;
                    width: 3.42rem;
                }
            }

            &__button-mobile {
                display: flex;
                align-items: center;
                background-color: #E75549;
                border-radius: 50px;
                border: none;

                font-size: 1.54rem;
                line-height: 2rem;
                text-transform: uppercase;
                color: $black;
                cursor: pointer;

                &__text {
                    padding: 1rem 1.4rem 1rem 0.3rem;
                    color: $white;
                }

                &__img {
                    margin: 0 0.3rem;
                    height: 3.42rem;
                    width: 3.42rem;
                }
            }

            &__cancelButton {
                margin-top: 1.3rem;
                display: flex;
                border: none;
                background-color: white;
                cursor: pointer;

                &__text {
                    padding-top: 2px;
                    font-size: 1.71rem;
                    font-family: 'Roboto', sans-serif;
                    line-height: 2rem;
                    color: #BCB7B3;
                    margin-left: 0.5rem;
                }

                &__img {
                    align-self: center;
                    height: 1.71rem;
                    width: 1.71rem;
                }
            }

        }

        &__gmail {
            &__wrapper {
                justify-content: center;
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            &__content {
                padding: 2.56rem 5.66rem;

                &__header {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    margin-bottom: 2.15rem;

                    &__img {
                        margin-bottom: 1.3rem;
                        align-self: center;
                    }

                    &__text {
                        font-size: 3.42rem;
                        line-height: 4.1rem;
                        color: $black;

                        &.red {
                            color: #E75549;
                        }
                    }
                }

                &__info {
                    &__text {
                        margin-bottom: 2.56rem;
                        font-size: 1.71rem;
                        font-family: 'Roboto', sans-serif;
                        line-height: 2rem;
                        text-align: center;
                        color: #56514D;
                        white-space: pre-line;
                    }

                    &__text.blue {
                        color: #5480A0;
                    }
                }

                &__button {
                    margin-top: 2.56rem;
                    display: flex;
                    align-items: center;
                    background-color: #E75549;
                    border-radius: 50px;
                    border: none;

                    font-size: 1.54rem;
                    line-height: 2rem;
                    text-transform: uppercase;
                    color: $black;
                    cursor: pointer;

                    align-self: center;

                    &.fail {
                        background: #0B0A0A;
                        color: $white;
                    }

                    &__text {
                        padding: 1rem 1.36rem 1rem 0.3rem;
                        color: $white;
                    }

                    &__img {
                        padding: 0.3rem;
                    }
                }

                &__cancelButton {
                    margin-top: 1.3rem;
                    display: flex;
                    border: none;
                    background-color: white;
                    cursor: pointer;

                    &__text {
                        font-size: 1.54rem;
                        line-height: 2rem;
                        color: #BCB7B3;
                        margin-left: 0.55rem;
                    }

                    &__img {
                        padding-top: 3px;
                    }
                }
            }
        }
    }
}

.active {
    background: #E75549;
    color: $white;
}

@media (max-width: 850px) {
    .account__modal__wrapper {
        width: calc(100vw - 32px);
        padding: 24px 20px 40px;
    }

    .account__modal__content__box {
        display: none;
    }

    .account__modal__content__mobile {
        display: block;
        margin-bottom: 24px;
    }

    .account {
        margin-top: 120px;
    }

    .account__container {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .account__info__title {
        display: none;
    }

    .account__photo {
        align-items: center;

        &__img {
            height: 120px;
            width: 120px;
        }
    }

    .account__buttons {
        margin-bottom: 0;
    }

    .account__photo__button {
        margin-top: 12px;

        &__img {
            height: 20px;
            width: 20px;
        }

        &__text {
            font-size: 14px;
            line-height: 20px;
            margin-left: 4px;
        }
    }

    .account__info {
        margin-top: 40px;
        margin-left: 0;
        padding: 0 15px;
        align-items: center;

        &__box {
            width: 100%;

            &__item {
                width: 100%;

                &__img {
                    width: 20px;
                    height: 20px;
                }

                &__input {
                    padding: 10px 16px;
                    width: 100%;
                    height: auto;
                    line-height: 19px;
                }
            }
        }
    }

    .account__buttons {
        margin-top: 60px;
        justify-content: center;
        display: flex;
    }

    .account__buttons__subs,
    .account__buttons__serv,
    .account__buttons__quer {
        font-size: 16px;
        line-height: 24px;
        padding: 5px 16px;
        margin: 0;
    }

    .account__wrap>.buy>.buy__wrapper {
        width: 100%;
        padding: 0 16px;
    }
}

@media (max-width: 850px) {
    .account__modal__gmail__wrapper {
        width: calc(100vw - 32px);
        padding: 24px 0 40px 0;
    }

    .account__modal__gmail__content__header__text {
        line-height: 26px;
        font-size: 22px;
    }

    .account__modal__gmail__content__info__text {
        font-size: 16px;
        line-height: 19px;
    }

    .account__modal__gmail__content__button__img {
        height: 24px;
        width: 24px;
        padding: 0 0 0 4px;
    }

    .account__modal__gmail__content__button__text {
        font-size: 14px;
        line-height: 21px;
        padding: 6px 16px 6px 8px;
    }

    .account__modal__gmail__content__header {
        margin-bottom: 24px;
    }

    .account__modal__gmail__content__button {
        margin-top: 24px;
    }

    .account__modal__gmail__content__cancelButton__text {
        font-size: 16px;
        margin-left: 6px;
    }

    .account__modal__gmail__content__cancelButton__img {
        width: 20px;
        height: 20px;
        padding-top: 2px;
    }
}