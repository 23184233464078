.header {
    height: 6.84rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 6.84rem;
    position: fixed;
    z-index: 1000000;
    background-color: white;

    @media (max-width: 850px) {
        padding: 0 18px;
    }

    &__left {
        display: flex;
        align-items: center;
        position: relative;

        &__mobile-button {
            display: none;

            &__label {
                display: none;
                background-image: url('./../images/header/mobile-menu.svg');
                background-repeat: no-repeat;
                width: 20px;
                height: 16px;
                margin-right: 1.02rem;
                cursor: pointer;
            }
        }

        &__toggle-button {
            transform: scale(0.8);
            position: relative;
            display: inline-block;
            width: 7.7rem;
            height: 4.28rem;
            margin: 0;
            vertical-align: top;
            border: 1px solid black;
            border-radius: 30px;
            outline: none;
            cursor: pointer;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            transition: 0.3s;
            transition: all 0.3s cubic-bezier(0.2, 0.85, 0.32, 1.2);
            background-color: black;

            &::after {
                content: "";
                text-align: center;
                padding-top: 0.85rem;

                display: inline-block;
                position: absolute;
                left: 0.25rem;
                top: 1.5px;

                width: 3.85rem;
                height: 2.91rem;
                background-color: #FAF6F6;
                border-radius: 50%;

                transform: translateX(0);
                transition: 0.3s;
                transition: all 0.3s cubic-bezier(0.2, 0.85, 0.32, 1.2);
            }

            &:checked::after {
                transform: translateX(calc(100% - 0.68rem));
            }

            &__text-ru {
                color: black;
                letter-spacing: -0.03em;
                font-size: 1.54rem;
                line-height: 2.05rem;
                position: absolute;
                left: 1.62rem;
                cursor: pointer;
            }

            &__text-en {
                color: #56514D;
                letter-spacing: -0.03em;
                font-size: 1.54rem;
                line-height: 2.05rem;
                position: absolute;
                left: 4.4rem;
                cursor: pointer;
            }

            &:checked~&__text-ru {
                color: #56514D;
            }

            &:checked~&__text-en {
                color: $black;
            }
        }

        &__logo {
            height: 2.1rem;
            width: 17.1rem;
            margin-left: 2.56rem;
        }
    }

    &__right {
        display: flex;
        align-items: center;

        &__nav {

            &__link {
                text-decoration: none;
                color: #56514D;
                font-size: 1.71rem;
                line-height: 2.5rem;
                margin-left: 8.56rem;
                transition: all .3s;
                border-bottom: 1px solid #FFF;

                @media (max-width: 1300px) {
                    margin-left: 4.3rem;
                }

                @media (max-width: 900px) {
                    margin-left: 2.56rem;
                }

                &:hover {
                    color: $black;
                    border-color: #0B0A0A;
                }

                &.actived {
                    color: $black;
                    border-color: #0B0A0A;
                }
            }

        }

        &__profile-logout {
            font-size: 1.71rem;
            line-height: 30px;
            text-transform: uppercase;
            color: #56514D;
            margin-left: 50px;
            background-color: inherit;
            border: none;
            cursor: pointer;
            padding-left: 26px;
            background-image: url('./../images/header/logout.svg');
            background-repeat: no-repeat;
            background-position: left 6px;

            @media (max-width: 1250px) {
                font-size: 1.54rem;
            }

            @media (max-width: 900px) {
                margin-left: 30px;
            }
        }

        &__profile {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            position: relative;

            &__content {
                top: 50px;
                position: absolute;
                display: none;
                flex-direction: column;
                background-color: inherit;
                align-items: flex-end;

                background-color: white;
                border-radius: 15px;

                right: 0;

                &.open {
                    display: flex;
                }

                @media (max-width: 600px) {
                    top: 30px;
                }

                &__wrapper {
                    padding: 10px;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                }

                &__el {
                    font-size: 1.71rem;
                    line-height: 1.71rem;
                    white-space: nowrap;
                    text-transform: uppercase;
                    color: #56514D;
                    text-decoration: none;

                    &.button {
                        padding-top: 1.71rem;
                        border: none;
                        background-color: inherit;
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        gap: 0.5rem;

                        &__img {
                            height: 1.71rem;
                            width: 1.71rem;
                        }
                    }

                    &:first-child {
                        padding-bottom: 10px;
                        border-bottom: 1px solid #BCB7B3;
                    }
                }
            }
        }

        &__profile-img {
            margin-left: 8.56rem;
            height: 3rem;
            width: 3rem;
            cursor: pointer;

            &__link {
                display: flex;
                align-items: center;
            }
        }

        &__login {
            display: flex;
            align-items: center;
            border: none;
            background: #0B0A0A;
            border-radius: 50px;
            margin-left: 100px;
            cursor: pointer;

            @media (max-width: 1100px) {
                margin-left: 30px;
            }

            &__text {
                padding: 0 1rem 0 0.35rem;
                font-size: 1.71rem;
                font-family: 'Roboto', sans-serif;
                line-height: 2.05rem;
                text-transform: uppercase;
                color: $white;

                @media (max-width: 1100px) {
                    padding: 6px 10px 4px 5px;
                    line-height: 20px;
                }
            }

            &__img {
                padding: 0.35rem 0;
                width: 3.42rem;
                height: 3.42rem;
            }
        }
    }
}

.header__left__mobile-menu {
    z-index: 10;
    position: absolute;
    top: -40px;
    left: -19px;
    width: 101vw;
    height: 110vh;
    background-color: #E75549;
    display: flex;
    flex-direction: column;
    gap: 100px;
    overflow-y: auto;

    transform: translateX(-150vw);
    transition: 0.5s;

    &__header {
        margin-top: 50px;
        display: flex;
        align-items: center;

        &__logo {
            height: 20px;
            width: 132px;
            margin-left: 16px;

            &__box {
                height: 20px;
                aspect-ratio: 132/20;
            }
        }

        &__toggle-button {
            position: relative;
            display: inline-block;
            width: 90px;
            height: 50px;
            margin: 0;
            vertical-align: top;
            border: 1px solid black;
            border-radius: 30px;
            outline: none;
            cursor: pointer;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            transition: 0.3s;
            transition: all 0.3s cubic-bezier(0.2, 0.85, 0.32, 1.2);
            background-color: black;
            transform: scale(0.5);

            &::after {
                content: "";
                text-align: center;
                padding-top: 10px;

                display: inline-block;
                position: absolute;
                left: 3px;
                top: 1.5px;

                width: 45px;
                height: 34px;
                background-color: #FAF6F6;
                border-radius: 50%;

                transform: translateX(0);
                transition: 0.3s;
                transition: all 0.3s cubic-bezier(0.2, 0.85, 0.32, 1.2);
            }

            &:checked::after {
                transform: translateX(calc(100% - 8px));
            }

            &:checked~.header__left__mobile-menu__header__text-ru {
                color: #56514D;
            }

            &:checked~.header__left__mobile-menu__header__text-en {
                color: $black;
            }
        }

        &__text-ru {
            font-family: 'Roboto', sans-serif;
            font-weight: 400;
            color: black;
            font-size: 16px;
            line-height: 2.05rem;
            position: absolute;
            top: 63px;
            left: 25px;
            cursor: pointer;
            transform: scale(0.65);

            @media (max-width: 850px) {
                top: 68px;
            }
        }

        &__text-en {
            font-family: 'Roboto', sans-serif;
            font-weight: 400;
            color: #56514D;
            font-size: 16px;
            line-height: 2.05rem;
            position: absolute;
            left: 45px;
            top: 63px;
            cursor: pointer;
            transform: scale(0.65);

            @media (max-width: 850px) {
                top: 68px;
            }
        }

        &__cross {
            cursor: pointer;
            background-image: url('./../images/header/mobile-cross.svg');
            border: none;
            border-radius: 100%;
            width: 24px;
            height: 24px;
            margin: 0 16px 5px auto;
        }

    }

    &__nav {
        display: flex;
        flex-direction: column;
        margin-left: 50px;

        &__el {
            margin-bottom: 16px;
            text-decoration: none;
            font-weight: 600;
            font-size: 48px;
            line-height: 58px;
            letter-spacing: -0.02em;
            color: $white;
        }
    }

    &__login {
        display: flex;
        flex-direction: column;
        margin-left: 50px;

        &__profile,
        &__logout {
            font-weight: 400;
            font-size: 2.05rem;
            line-height: 29px;
            color: $white;
            text-decoration: none;
        }

        &__logout {
            cursor: pointer;
            border: none;
            background-color: #E75549;
            background-image: url('./../images/header/mobile-logout.svg');
            background-repeat: no-repeat;
            background-position: left;
            align-self: flex-start;
            padding: 1px 0 0 26px;
            margin: 17px 0 100px 0;
        }

        &__signin {
            display: flex;
            border: none;
            background: $white;
            border-radius: 50px;
            align-items: center;
            align-self: flex-start;
            margin-bottom: 100px;
            cursor: pointer;

            &__img {
                padding: 0 0 0 4px;
            }

            &__text {
                font-family: 'Roboto', sans-serif;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                text-transform: uppercase;
                color: $black;
                padding: 8px 16px 6px 8px;
            }
        }
    }
}

@media (max-width: 1250px) {
    .header__right__nav__link {
        font-size: 1.54rem;
    }

    .header__right__nav__link:first-child {
        margin-left: 0;
    }
}

@media (max-width: 1100px) {
    .header {

        &__left__logo {
            width: 100px;
            margin-left: 0;
        }
    }

    .header__right__nav__link:first-child {
        margin-left: 0;
    }

    .header__right__nav__link {
        margin-left: 50px;
    }

    .header__right__profile-img {
        margin-left: 50px;
    }
}

@media (max-width: 850px) {

    .header__right__nav,
    .header__left__toggle-button,
    .header__left__toggle-button__text-ru,
    .header__left__toggle-button__text-en {
        display: none;
    }

    .header__right__profile-img {
        height: 2.05rem;
        width: 2.05rem;
    }

    .header__left__mobile-button {

        &:active,
        &:focus {
            outline: none !important;
        }

        &:checked~.header__left__mobile-menu {
            transform: translateX(0vw);
        }

        &__label {
            display: block;
        }
    }

    .header__right__login {
        margin-left: 0;

        &__text {
            font-family: 'Roboto', sans-serif;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
        }

        &__img {
            width: 24px;
            height: 24px;
            padding: 0 0 0 4px;
        }
    }
}

.languageDropdown {
    &__image {
        width: 2.73rem;
        height: 2.73rem;

        @media screen and (max-width: 850px) {
            width: 32px;
            height: 32px;
        }
    }

    &__el {
        display: flex;
        align-items: center;
        column-gap: 6px;
    }

    &__button {
        display: flex;
        align-items: center;
        column-gap: 6px;
        padding: 8px;
        background-image: none !important;
    }

    &__content {
        background-color: #FDFAFA !important;
        border-radius: 30px;
    }

    &__buttonOpened {
        
    }
}