.querie {
    &__table {

        &__el {
            display: flex;
            justify-content: center;
            position: relative;
            align-self: center;

            &.full {
                width: 100%;
            }

            &__status {
                width: 100%;
                border: 1px solid #E4E3E2;
                border-radius: 30px;
                background-color: white;
                cursor: pointer;
                white-space: nowrap;
                background-image: url('./../images/dashboard/arrow-more.svg');
                background-position: calc(100% - 0.35rem);
                background-repeat: no-repeat;
                background-size: 1.71rem;
                padding-right: 2.14rem;

                @media screen and (max-width: 850px) {
                    font-size: 16px;
                    line-height: 19px;
                }

                &.cancelled {
                    color: #E75549;
                }

                &.paid {
                    color: #14AE5C;
                }

                &.delete {
                    padding-left: 1rem;
                }

                &.open {
                    &+.querie__table__el__status__content {
                        display: flex;
                    }
                }

                &__content {
                    z-index: 100;
                    top: 110%;
                    min-width: 100%;
                    position: absolute;
                    display: block;
                    background: #FDFAFA;
                    padding: 0 1rem;
                    display: none;
                    flex-direction: column;

                    @media screen and (max-width: 850px) {
                        right: 0;
                    }

                    &.big {
                        @media screen and (max-width: 850px) {
                            right: auto;
                            width: calc(100vw - 20px);
                            left: -70px;
                        }
                    }

                    &__button {
                        display: flex;
                        gap: 0.75rem;
                        background: #E75549;
                        border-radius: 50px;
                        border: none;
                        align-self: center;
                        align-items: center;
                        cursor: pointer;

                        &__img {
                            height: 3.42rem;
                            width: 3.42rem;
                            margin: 0.35rem 0 0.35rem 0.35rem;
                        }

                        &__text {
                            font-size: 1.54rem;
                            line-height: 2.4rem;
                            letter-spacing: -0.03em;
                            text-transform: uppercase;
                            color: #FDFAFA;
                            margin-right: 1.3rem;
                        }
                    }

                    &__wrapper {
                        padding: 3.42rem;
                        display: flex;
                        flex-direction: column;
                        gap: 2.56rem;
                    }

                    &.servicesDropdown {
                        left: 0;
                        min-width: 43rem;
                    }

                    &__elem {
                        display: flex;
                        flex-direction: column;
                        gap: 1.2rem;

                        &__time-box {
                            display: grid;
                            gap: 1rem;
                            grid-template-columns: 1fr 1fr 1fr;

                            &__el {
                                border: 1px solid #BCB7B3;
                                border-radius: 50px;
                                font-size: 1.71rem;
                                line-height: 2.56rem;
                                text-transform: uppercase;
                                color: #0B0A0A;
                                padding: 0.5rem 3.1rem;
                                background-color: inherit;
                                cursor: pointer;

                                &.active {
                                    background: #E75549;
                                    color: #FDFAFA;
                                    border: 1px solid #E75549;
                                    border-radius: 50px;
                                }
                            }
                        }

                        &__title {
                            font-size: 1.71rem;
                            line-height: 2rem;
                            color: #56514D;
                        }

                        &__dropdown {
                            width: 100%;
                        }
                    }

                    &__el {
                        white-space: nowrap;
                        text-align: end;
                        border-top: 1px solid #E4E3E2;
                        padding: 1.05rem;
                        font-size: 1.71rem;
                        line-height: 2rem;
                        color: #0B0A0A;
                        font-family: 'Roboto', sans-serif;
                        cursor: pointer;
                        text-align: center;


                        @media screen and (max-width: 850px) {
                            font-size: 16px;
                            line-height: 19px;
                        }

                        &:first-child {
                            border: none;
                        }

                        &.servicesDropdown {
                            display: flex;
                            cursor: inherit;
                        }

                        &__text,
                        &__free {
                            display: flex;
                            align-items: center;
                            font-family: "Roboto", sans-serif;
                        }

                        &__input {
                            width: 6rem;
                            height: 3.42rem;
                            margin: 0 0.8rem 0 auto;
                            border: 1px solid #BCB7B3;
                            border-radius: 4px;
                            display: flex;
                            align-items: center;
                            text-align: center;
                            font-family: "Roboto", sans-serif;
                            font-size: 1.71rem;
                            line-height: 2rem;

                            &:focus {
                                outline: none;
                            }

                            &::placeholder {
                                color: #BCB7B3;
                            }
                        }
                    }
                }
            }
        }

        &__row {
            display: grid;
            grid-template-columns: 180fr 203fr 180fr 180fr 520fr 180fr;
            gap: 4.28rem;
            padding: 1.71rem 0 2.56rem;
            border-top: 1px solid #BCB7B3;

            &:first-child {
                border: none;
            }

            &:nth-child(2) {
                border: none;
            }
        }

        &__title {
            display: grid;
            grid-template-columns: 180fr 203fr 180fr 180fr 520fr 180fr;
            gap: 4.28rem;
            margin: 2.56rem 0 1rem;

            &__el {
                white-space: nowrap;
                justify-self: center;
            }
        }
    }
}