.about {
    width: 100%;
    background-color: white;

    &__promo {
        height: calc(100vh - 6.84rem);
        padding: 0 10px 10px 10px;

        &__wrapper {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 30px;
            background-color: #E75549;
            position: relative;
            overflow: hidden;
            z-index: 100;
        }

        &__shield {
            width: 100%;
            height: 500px;
            border-radius: 30px;
            background-color: #E75549;
            z-index: 5;
            position: absolute;
            transform: scaley(1);
            transform-origin: right bottom;
            transition: 1s;
        }

        &__shield.actived {
            transform: scaley(0);
        }

        &__box {
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            &__title {
                font-family: 'Roboto', sans-serif;
                font-weight: 700;
                font-size: 300px;
                line-height: 360px;
                color: $white;
                text-align: center;
                letter-spacing: -0.02em;
            }

            &__text {
                font-family: 'Roboto', sans-serif;
                font-weight: 400;
                font-size: 54px;
                letter-spacing: -0.021em;
                line-height: 120%;
                color: $white;
                white-space: pre-line;
            }

        }

        &__bg {
            position: absolute;
            bottom: 0;
            transition: 1s;
            transform: translateY(600px);
        }

        &__bg {
            left: 50px;
            z-index: 10;

            &.actived {
                transform: translateY(0px);
            }
        }
    }

    &__why {
        padding: 0 85px;
        display: flex;
        flex-direction: column;
        margin-bottom: 400px;

        &__el {
            height: 300px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            z-index: 10;

            &__rotate {
                position: absolute;
                margin-left: auto;
                margin-right: auto;
                left: 0;
                right: 0;
                text-align: center;
                width: 865px;
                height: 865px;

                &__img {
                    position: absolute;
                    margin-left: auto;
                    margin-right: auto;
                    left: 0;
                    right: 0;
                    text-align: center;
                    width: 85px;
                    height: 85px;
                    opacity: 0;

                    &.first {
                        margin-top: 390px;
                    }

                    &.second {
                        margin-top: 390px;
                    }

                    &.third {
                        margin-top: 690px;
                    }

                    &.fouth {
                        margin-top: 990px;
                    }
                }
            }

            &.left {
                align-self: flex-start;
            }

            &.right {
                align-self: flex-end;
            }

            &__title {
                font-family: 'Roboto', sans-serif;
                font-weight: 400;
                font-size: 54px;
                letter-spacing: -0.021em;
                line-height: 120%;

                color: $black;
                margin-bottom: 30px;

                &.main {
                    font-family: 'Roboto', sans-serif;
                    font-weight: 700;
                    font-size: 96px;
                    letter-spacing: -0.02em;
                    line-height: 115px;
                    margin-bottom: 50px;
                    white-space: pre-line;
                }
            }

            &__text {
                font-family: 'Roboto', sans-serif;
                font-weight: 400;
                font-size: 20px;
                line-height: 24px;
                color: #56514D;
                white-space: pre-line;

                & font {
                    color: $black;
                    font-family: 'Roboto', sans-serif;
                    white-space: pre-line;
                }
            }
        }
    }

    &__life {
        height: calc(100vh - 80px);
        display: flex;
        align-items: center;
        justify-content: center;

        &__text {
            font-family: 'Roboto', sans-serif;
            font-weight: 400;
            font-size: 54px;
            letter-spacing: -0.021em;
            line-height: 120%;
            letter-spacing: -0.023em;
            color: $black;
            white-space: pre-line;
            max-width: 80vw;
            
            & font {
                font-family: 'Roboto', sans-serif;
                white-space: pre-line;
            }
        }
    }

    &__team {
        min-height: calc(100vh - 80px);
        display: flex;
        flex-direction: column;

        &__wrapper {}

        &__header {
            padding: 0 80px;
            display: flex;
            justify-content: space-between;
            margin-bottom: 80px;

            &__title {
                white-space: nowrap;
                font-family: 'Roboto', sans-serif;
                font-weight: 700;
                font-size: 96px;
                letter-spacing: -0.02em;
                line-height: 115px;
                letter-spacing: -0.02em;
                color: $black;
            }

            &__buttons {
                width: 205px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                &__left,
                &__right {
                    border-radius: 100%;
                    border: 1px solid #BCB7B3;
                    background-color: white;
                    height: 85px;
                    width: 85px;
                    background-image: url('./../images/about/slider-arrow.svg');
                    background-repeat: no-repeat;
                    background-position: center;
                    cursor: pointer;

                    &:active {
                        border-color: $black;
                        background-image: url('./../images/about/slider-arrow-black.svg');
                    }
                }

                &__right {
                    transform: matrix(-1, 0, 0, 1, 0, 0);
                }
            }
        }

        &__slider {
            margin: 0 80px;
            margin-bottom: 170px;
            outline: none;

            &__card {
                align-self: center;
                justify-self: center;
                display: flex !important;
                flex-direction: column;
                height: 950px;
                max-width: 420px;
                cursor: pointer;
                transform: scale(0.9);

                &__img {
                    border-radius: 30px;
                    margin-bottom: 30px;
                    width: 420px;
                    height: 420px;
                }

                &__position {
                    padding: 6px 36px;
                    background-color: $white;
                    box-shadow: 0px 0px 15px rgba(79, 79, 79, 0.13);
                    border-radius: 50px;
                    margin-bottom: 15px;
                    font-weight: 400;
                    font-size: 24px;
                    line-height: 29px;
                    color: $black;
                    text-transform: uppercase;
                    align-self: flex-start;
                }

                &__name {
                    font-family: 'Roboto', sans-serif;
                    font-size: 54px;
                    letter-spacing: -0.021em;
                    line-height: 120%;
                    color: $black;
                    margin-bottom: 15px;
                    white-space: pre;
                }

                &__info {
                    font-family: 'Roboto', sans-serif;
                    font-size: 20px;
                    line-height: 24px;
                    color: #56514D;
                    border-top: 1px solid #E4E3E2;
                    padding: 15px 0;
                }
            }
        }
    }
}

.questions_box_img {
    width: 25.06rem;
    height: 33.89rem;

    @media screen and (max-width: 850px) {
        width: 180px;
        height: 220px;
    }
}



.slick-slide>div {
    display: flex;
    justify-content: center;
}

@media (max-width: 1670px) {
    .about__promo__box__title {
        font-size: 250px;
    }

    .about__promo__box__text {
        font-size: 48px;
    }
}

@media (max-width: 1600px) {

    .about__promo__bg {
        height: 350px;
    }


    .questions__box__img {
        margin-bottom: 100px;

    }

    .about__team__slider__card {
        width: 300px !important;
        height: auto;
    }

    .about__team__slider__card__img {
        height: 300px;
        width: 300px;
    }

    .about__team__slider__card__name {
        font-size: 36px;
    }

    .about__team__slider__card__position {
        font-size: 24px;
    }

    .about__why__el__title.main {
        font-size: 64px;
        line-height: 65px;
        margin-bottom: 20px;
    }

}


@media (max-width: 1400px) {
    .about__promo__box__title {
        font-size: 200px;
    }

    .about__promo__box__text {
        font-size: 36px;
    }

    .about__life__text {
        font-size: 36px;
    }

    .about__why__el__title {
        font-size: 46px;
    }

    .about__team__header__title {
        font-size: 64px;
    }

}

@media (max-width: 1100px) {
    .about__promo__box__title {
        font-size: 150px;
    }

    .about__promo__box__text {
        font-size: 36px;
    }

    .about__life__text {
        font-size: 36px;
    }


    .about__promo__bg {
        height: 300px;
    }

    .about__why__el__title.main {
        font-size: 50px;
    }

    .about__why__el__title {
        font-size: 36px;
    }

    .about__why__el__text {
        font-size: 16px;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
    }

    .about__why__el__rotate {
        width: 600px;
    }

    .about__why__el__rotate__img.first,
    .about__why__el__rotate__img.second,
    .about__why__el__rotate__img.third,
    .about__why__el__rotate__img.fouth {
        height: 55px;
    }

    .about__why__el__rotate__img.first {
        margin-top: 405px;
    }

    .about__why__el__rotate__img.second {
        margin-top: 405px;
    }

    .about__why__el__rotate__img.third {
        margin-top: 705px;
    }

    .about__why__el__rotate__img.fouth {
        margin-top: 1005px;
    }
}

@media (max-width: 800px) {

    .about__team__slider__card {
        width: 250px !important;
        height: auto;
    }

    .about__team__slider__card__img {
        height: 250px;
        width: 250px;
    }

    .about__promo__bg {
        height: 250px;
    }

    .about__why__el.main {
        width: 250px;
    }

    .about__why__el {
        width: 200px;
    }

    .about__why {
        margin-bottom: 100px;
    }

    .about__team__slider__card__position {
        font-size: 16px;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        padding: 3px 12px;
        margin-bottom: 10px;
    }

    .about__team__slider__card__name {
        font-size: 24px;
    }

    .about__team__slider__card__img {
        margin-bottom: 10px;
    }

    .about__team__slider__card__info {
        padding: 10px 0;
        font-size: 16px;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
    }

    .about__team__slider__card__name {
        margin-bottom: 10px;
    }
}

@media (max-width: 850px) {
    .about__promo__box__title {
        font-size: 90px;
        height: 300px;
    }

    .about__promo__box__text {
        font-size: 36px;
        padding: 0 16px;
    }
}

@media (max-width: 600px) {

    .about__team {
        margin-bottom: 50px;
    }


    .about__promo__wrapper {
        align-items: flex-start;
    }

    .about__promo__box__title {
        font-size: 60px;
        height: 250px;
    }

    .about__promo__box__text {
        font-size: 28px;
        padding: 0 16px;
    }

    .about__promo__bg {
        left: 10px;
    }

    .about__life__text {
        font-size: 28px;
        width: 320px;

        br {
            display: none;
        }
    }

    .about__life {
        justify-content: flex-start;
        padding: 0 16px;
    }

    .about__why {
        padding: 0 16px;
        overflow: hidden;
        position: relative;
    }

    .about__why__el.right {
        align-self: flex-start;
    }

    .about__why__el.main {
        width: 100%;
        margin-bottom: 200px;

        .about__why__el__text>br {
            display: none;
        }

        .about__why__el__title {
            font-size: 48px;
        }

    }

    .about__why__el__text {
        white-space: inherit;
    }

    .about__why__el__title {
        font-size: 28px;
    }

    .about__why__el {
        height: auto;
        margin-bottom: 160px;
    }

    .about__team__header {
        padding: 0 16px;
        margin-bottom: 30px;
    }

    .about__team__header__title {
        font-size: 48px;
        width: 100%;
        text-align: center;
    }

    .about__team__header__buttons {
        display: none;
    }

    .about__team__slider__card__position {
        font-size: 18px;
        line-height: 22px;
    }

    .about__team__slider__card__name {
        font-size: 28px;
    }

    .about__team__slider__card__info {
        font-size: 16px;
        line-height: 19px;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
    }

    .about__why__el__rotate__img {
        left: 170px;
    }

    .about__why__el__rotate__img.first {
        opacity: 1;
    }

    .about__why__el__rotate__img.third {
        margin-top: 405px;
    }

    .about__why__el__rotate__img.fouth {
        margin-top: 405px;
    }

    .about__promo__box {
        margin: 0 16px;
    }

    .about__why__el__rotate__img {
        margin-right: 0;
        margin-left: 88px;
    }

    .about__team__slider {
        margin: 0;
    }

    .about__team__wrapper {
        margin-bottom: 30px;
    }

}

@media (max-height: 650px) and (max-width: 650px) {
    .about__promo__bg {
        height: 150px;
    }
}

@media (max-width: 400px) {
    .about__promo__box__title {
        font-size: 55px;
    }

    .about__promo__box__text {
        font-size: 16px;
    }

    .about__promo__bg {
        left: -4px;
    }
}