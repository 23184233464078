.service {
    display: flex;
    flex-direction: column;

    &__table {

        &__el__img {
            cursor: pointer;
            align-self: center;
        }

        &__img {
            justify-self: center;
            align-self: center;
            max-width: 6.85rem;
        }

        &__row {
            cursor: pointer;
            display: grid;
            grid-template-columns: 260fr 350fr 80fr 100fr 200fr 80fr 120fr 180fr 20fr;
            gap: 1.28rem;
            padding: 1.71rem 0;
            border-top: 1px solid #BCB7B3;

            &:first-child {
                border: none;
            }

            &:nth-child(2) {
                border: none;
            }
        }

        &__title {
            display: grid;
            grid-template-columns: 260fr 350fr 80fr 100fr 200fr 80fr 120fr 180fr 20fr;
            gap: 1.28rem;
            margin: 2.56rem 0 1rem;

            &__el {
                justify-self: center;
                white-space: nowrap;
            }
        }
    }
}