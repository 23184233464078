.faq {
    width: 100%;
    background-color: white;

    &__promo__bg {
        height: 47rem !important;
    }

    &__first-block {
        height: calc(100vh - 6.84rem);
        padding: 0 1rem 1rem 1rem;

        &__wrapper {
            overflow: hidden;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 2.56rem;
            background-color: #E75549;
            position: relative;
        }

        &__box {
            width: 72rem;
            height: 27.3rem;
            display: flex;
            flex-direction: column;

            &__text {
                font-family: 'Roboto', sans-serif;
                font-weight: 400;
                font-size: 4.62rem;
                line-height: 120%;
                letter-spacing: -0.021em;
                color: $white;

                &.desktop {
                    @media (max-width: 600px) {
                        display: none;
                    }
                }

                &.mobile {
                    display: none;

                    &.first {
                        margin-bottom: 20px;
                    }

                    @media (max-width: 600px) {
                        display: block;
                        line-height: 40px !important;
                    }
                }

            }

            &__img {
                margin-top: 6.84rem;
                height: 10.2rem;
                width: 10.2rem;
            }
        }

        &__bg {
            position: absolute;
            bottom: 0;
            right: 4.2rem;
            transition: 1s;
            transform: translateY(600px);
            z-index: 10;

            &.actived {
                transform: translateY(0px);
            }
        }

        &__question {
            position: absolute;
            right: 5.13rem;
            bottom: 29rem;
            z-index: 10;
        }
    }

    &__second-block {
        height: calc(100vh - 6.84rem);
        min-height: 106.84rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &__box {
            display: flex;
            flex-direction: column;
            align-items: center;

            @media (max-width: 850px) {
                width: 100%;
            }

            &__title {
                font-family: 'Roboto', sans-serif;
                font-weight: 700;
                font-size: 8.21rem;
                letter-spacing: -0.02em;
                line-height: 9.84rem;
                letter-spacing: -0.02em;
                color: $black;
                margin-bottom: 8.56rem;
            }

            &__button {
                width: 100rem;
                display: flex;
                flex-direction: column;
                border: 1px solid #BCB7B3;
                border-radius: 40px;
                background-color: white;
                cursor: pointer;
                margin-bottom: 2.56rem;
                transition: all .3s;

                &:hover {
                    border: 1px solid $black;
                }

                &:hover>&__content>&__text {
                    color: $black;
                }

                &__text {
                    font-family: 'Roboto', sans-serif;
                    font-weight: 400;
                    font-size: 4.62rem;
                    line-height: 120%;
                    letter-spacing: -0.021em;
                    color: #56514D;
                    padding: 0 0 0 3.42rem;
                    transition: all .3s;
                }

                &__img {
                    padding: 0.9rem 0;
                    height: 7.7rem;
                    width: 7.7rem;
                    transition: all .5s;

                    &.open {
                        rotate: 90deg;
                        padding: 0 0.9rem;
                    }
                }

                &__content {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    align-items: center;
                }

                &__open {
                    flex-direction: column;
                    gap: 2px;
                    padding: 0 3.42rem 1.56rem 3.42rem;
                    display: none;

                    &__el {
                        text-align: start;
                        font-family: 'Roboto', sans-serif;
                        font-weight: 400;
                        font-size: 1.56rem;
                        line-height: 23px;
                        color: #56514D;
                    }
                }
            }
        }
    }

    &__third-block {
        height: calc(100vh - 6.84rem);
        padding: 0 10px 10px 10px;

        &__wrapper {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 2.56rem;
            background-color: #E75549;
        }

        &__box {
            width: 70%;
            display: flex;
            justify-content: space-around;

            @media (max-width: 850px) {
                height: 55%;
            }

            &__left {
                display: flex;
                flex-direction: column;
                justify-content: center;

                &__text {
                    font-family: 'Roboto', sans-serif;
                    font-weight: 400;
                    font-size: 4.62rem;
                    line-height: 120%;
                    letter-spacing: -0.021em;
                    color: $white;
                    white-space: pre-line;
                }

                &__button {
                    margin-top: 50px;
                    align-self: flex-start;
                    display: flex;
                    align-items: center;
                    border-radius: 50px;
                    background-color: $white;
                    border: none;
                    cursor: pointer;

                    &__text {
                        font-weight: 400;
                        font-size: 18px;
                        line-height: 24px;
                        text-transform: uppercase;
                        color: $black;
                        padding: 12px 16px 12px 8px;
                    }

                    &__img {
                        padding: 4px 0 4px 4px;
                    }
                }
            }
        }
    }
}

.mobile-line {
    display: none;
}

@media (max-width: 1600px) {
    .faq__first-block__bg {
        height: 600px;
    }
}

@media (max-width: 1400px) {
    .faq__second-block__box__button {
        width: 900px;
    }
}

@media (max-width: 1100px) {

    .faq__second-block,
    .faq__third-block {
        min-height: 900px;
    }

    .faq__first-block__bg {
        height: 400px;
    }

}

.faq__third-block__box__right {
    width: 25.06rem;
    height: 33.89rem;

    @media screen and (max-width: 850px) {
        width: 180px;
        height: 220px;
    }
}

@media (max-width: 1000px) {
    .faq__second-block__box__button {
        width: 800px;
    }

    .faq__second-block__box__button__img {
        height: 70px;
        width: 70px;
    }

    .faq__second-block__box__button__text {
        font-size: 26px;
    }

    .faq__second-block__box__button__open__el {
        font-size: 18px;
    }

    .faq__third-block__box__left__text {
        font-size: 30px;
    }

    .faq__third-block__box__left__button {
        width: 210px;

        &__text {
            font-size: 16px;
            font-weight: 400;
        }
    }

    .faq__third-block__box__right {
        height: 200px;
    }
}

@media (max-width: 900px) {
    .faq__second-block__box__button {
        width: 600px;
    }

    .faq__first-block__box {
        width: 550px;
    }
}


@media (max-width: 850px) {
    .faq__first-block__wrapper {
        align-items: baseline;
        overflow: hidden;
    }

    .faq__first-block__box {
        margin-top: 2.56rem;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .faq__first-block__box__text {
        font-size: 28px;
        text-align: center;
        line-height: 60px;
        padding: 0 6px;
    }

    .faq__first-block__box__img {
        height: 6.84rem;
        width: 6.84rem;
        margin-top: 40px;
    }



    .faq__second-block__box__title {
        font-size: 48px;
        margin-bottom: 20px;
    }

    .faq__second-block__box__button {
        width: 100%;
        height: auto;
        margin: 12px auto;
    }

    .faq__second-block__box__button__text {
        font-size: 18px;
        padding: 13px 24px;
        text-align: start;
    }

    .faq__second-block__box__button__open {
        padding: 0 24px 15px 24px;

        &__el {
            font-size: 14px;
        }
    }

    .faq__second-block__box__button {
        border-radius: 20px;
    }

    .faq__second-block__box__button__img {
        height: 40px;
        width: 40px;
        padding: 0 5px 0 0;

        &.open {
            padding: 5px 0 0 0;
        }
    }


    .faq__first-block__bg {
        right: 0px;
    }


    .faq__third-block__box__left {
        justify-content: flex-start;
    }

    .faq__third-block__box__right {
        align-self: flex-end;
        width: 170px;
    }

    .faq__third-block__box__left__text {
        font-size: 28px;
        width: 185px;
    }

    .faq__third-block__box__left__button__text {
        padding: 5px 16px 5px 4px;
        font-size: 14px;
        white-space: nowrap;
    }

    .faq__third-block__box__left__button__img {
        height: 24px;
        width: 24px;
    }

    .faq__third-block__box__left__button {
        width: auto;
        align-self: flex-start;
        letter-spacing: -0.7px;
    }

    .mobile-line {
        height: 2px;
        width: 300px;
        display: block;
        position: absolute;

        &.top {
            left: 57%;
            top: 26%;
        }

        &.bottom {
            right: 58%;
            bottom: 32%;
        }
    }
}


.faq__second-block__box {
    padding: 0 16px;
    margin-bottom: 50px;
}

.faq__third-block__wrapper {
    position: relative;
    overflow: hidden;
}

@media (max-width: 600px) {
    .faq__third-block__box {
        height: 60%;
    }
}

@media (max-width: 400px) {
    .faq__third-block__box__right {
        width: 136px;
        height: 160px;
    }
}

@media (max-width: 36.84rem) {
    .faq__first-block__bg {
        height: 335px;
    }
}

@media (max-width: 350px) {
    .faq__first-block__bg {
        right: -7px;
    }
}

@media (max-height: 650px) {
    .faq__first-block__bg {
        height: 250px;
    }
}

@media (max-width: 321px) {
    .mobile-line.bottom {
        right: 45%;
        bottom: 26%;
    }
}