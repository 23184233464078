.buy {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 17.1rem;

    &.wrap {
        @media (max-width: 850px) {
            padding: 0 3%;
        }
    }

    &__wrapper {
        width: 80%;

        @media (max-width: 1450px) {
            width: 100%;
        }
    }

    &__header {
        display: flex;
        position: relative;

        &__back-button {
            position: absolute;
            border: none;
            border-radius: 100%;
            background-color: white;
            left: -3rem;
            top: 0.7rem;

            &__img {
                cursor: pointer;
                height: 3.42rem;
                width: 3.42rem;
            }
        }

        &__title {
            font-family: 'Roboto', sans-serif;
            font-weight: 400;
            font-size: 4.6rem;
            line-height: 120%;
            letter-spacing: -0.021em;
            color: $black;
            margin-left: 2.56rem;
            margin-bottom: 5.8rem;
        }
    }

    &__container {
        display: flex;
        justify-content: space-between;
        margin-left: 2.56rem;

        &__left {

            &__el:first-child {
                border-bottom: 1px solid #BCB7B3;
                margin-bottom: 2.56rem;
            }

            &__el {
                display: flex;

                &__interkassa {
                    width: 13rem;
                    
                    @media (max-width: 850px){
                        width: 153px;
                        margin-top: 15px;
                    }
                }

                &__number {
                    width: 4.1rem;
                    height: 4.1rem;
                    margin-right: 2.56rem;
                }

                &__box {
                    display: flex;
                    flex-direction: column;

                    &__title {
                        font-size: 3.42rem;
                        line-height: 4.1rem;
                        color: $black;
                        margin-bottom: 2.56rem;

                        &.false {
                            color: #BCB7B3;
                        }
                    }

                    &__text {
                        font-size: 1.71rem;
                        font-family: 'Roboto', sans-serif;
                        line-height: 2.1rem;
                        color: #56514D;
                        margin-bottom: 2.56rem;
                        white-space: pre-line;

                        &>.blue {
                            color: #5480A0;
                        }
                    }

                    &__button {
                        display: flex;
                        align-items: center;
                        background: #E75549;
                        border-radius: 50px;
                        border: none;
                        align-self: flex-start;
                        cursor: pointer;
                        margin-bottom: 2.56rem;

                        &__text {
                            font-weight: 400;
                            font-size: 1.54rem;
                            line-height: 2.1rem;
                            text-transform: uppercase;
                            color: $white;
                            padding: 0 1.36rem 0 0.7rem;
                        }

                        &__img {
                            margin: 0.34rem 0 0.34rem 0.34rem;
                            height: 3.42rem;
                            width: 3.42rem;
                        }
                    }
                }

                &__buttons {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 4.3rem;
                    min-width: 52rem;

                    @media screen and (max-width: 850px) {
                        justify-content: space-around;
                    }

                    &__button {
                        background: #FDFAFA;
                        border: none;
                        box-shadow: 0px 2.9rem 4.5rem rgba(79, 79, 79, 0.06);
                        border-radius: 15px;
                        cursor: pointer;
                        transition: all .3s;

                        &:hover {
                            box-shadow: 0px 2.14rem 3.85rem rgba(79, 79, 79, 0.1);
                        }

                        &__img {
                            padding: 2.1rem 1.36rem;
                            width: 20.55rem;

                            @media (max-width: 850px) {
                                width: 40vw;
                            }
                        }
                    }

                    &__button.actived {
                        box-shadow: 0px 0.7rem 1.1rem rgba(79, 79, 79, 0.13);

                        &:hover {
                            box-shadow: 0px 0.7rem 1.1rem rgba(79, 79, 79, 0.13);
                        }
                    }
                }

                &__pay-button {
                    margin: 4.3rem 0 14.55rem 6.66rem;
                    display: flex;
                    align-items: center;
                    background-color: #E75549;
                    border-radius: 50px;
                    border: none;

                    font-size: 1.54rem;
                    line-height: 2.1rem;
                    text-transform: uppercase;
                    color: $black;
                    cursor: pointer;

                    &__text {
                        padding: 0 1.36rem 0 0.34rem;
                        color: $white;
                    }

                    &__img {
                        padding: 0.34rem;
                        height: 3.42rem;
                        width: 3.42rem;
                    }
                }
            }
        }

        &__right {
            display: flex;
            flex-direction: column;
            justify-self: center;

            &__wrapper {
                padding: 2.56rem;
                background: #FDFAFA;
                box-shadow: 0px 2.1rem 4.1rem rgba(79, 79, 79, 0.1);
                border-radius: 30px;
            }

            &__title {
                margin-bottom: 2.56rem;
                font-size: 3.42rem;
                line-height: 4.1rem;
                color: $black;
            }

            &__list {
                border-bottom: 1px solid #BCB7B3;
                margin-bottom: 1.3rem;

                &__el {
                    display: flex;
                    margin-bottom: 1.3rem;
                    align-items: center;

                    &__text {
                        font-size: 1.71rem;
                        font-family: 'Roboto', sans-serif;
                        line-height: 2.1rem;
                        color: $black;
                        margin-left: 0.5rem;
                    }
                }
            }

            &__price {
                display: flex;
                align-items: center;

                &__result {
                    font-size: 1.71rem;
                    font-family: 'Roboto', sans-serif;
                    line-height: 2.1rem;
                    color: #BCB7B3;
                }

                &__money {
                    font-family: 'Roboto', sans-serif;
                    font-weight: 400;
                    margin: 0 0.9rem 0 2.56rem;
                    font-size: 4.6rem;
                    line-height: 120%;
                    letter-spacing: -0.021em;
                    color: $black;
                }

                &__monthly {
                    white-space: nowrap;
                    font-size: 1.71rem;
                    font-family: 'Roboto', sans-serif;
                    line-height: 2.1rem;
                    color: #56514D;

                    &.none {
                        opacity: 0;
                    }
                }
            }
        }
    }

    &__modal {

        &__content {
            padding: 2.56rem 10.44rem;

            &__header {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin-bottom: 2.14rem;

                &__img {
                    margin-bottom: 1.3rem;
                }

                &__text {
                    font-size: 3.42rem;
                    line-height: 4.1rem;
                    color: #14AE5C;
                }
            }

            &__info {
                width: 27.4rem;

                &__title {
                    font-size: 2.1rem;
                    line-height: 29px;
                    text-transform: uppercase;
                    color: #56514D;
                    margin-bottom: 1.3rem;
                    text-align: center;
                }

                &__list {
                    margin-bottom: 2.56rem;

                    &__el {
                        display: flex;
                        align-items: center;
                        margin-bottom: 1.3rem;

                        &__text {
                            font-size: 1.71rem;
                            font-family: 'Roboto', sans-serif;
                            line-height: 2.1rem;
                            color: $black;
                            margin-left: 0.5rem;
                        }
                    }
                }
            }

            &__button {
                display: flex;
                align-items: center;
                background-color: #E75549;
                border-radius: 50px;
                border: none;

                font-size: 1.54rem;
                line-height: 2.1rem;
                text-transform: uppercase;
                color: $black;
                cursor: pointer;

                &__text {
                    padding: 0 1.36rem 0 0.34rem;
                    color: $white;
                }

                &__img {
                    padding: 0.34rem;
                    height: 3.42rem;
                    width: 3.42rem;
                }

            }
        }
    }
}

.contact__modal__content__button-close.buy-close {
    margin-top: 1.3rem;
}

@media (max-width: 850px) {
    .buy {
        margin-top: 140px;
    }

    .buy__container__left__el__number {
        height: 24px;
        width: 24px;
        margin-top: 12px;
    }

    .buy__container__left__el__box__title {
        font-size: 24px;
        margin-bottom: 10px;
    }

    .buy__container__left__el:first-child {
        border: none;
    }

    .buy__container__left__el {
        margin-bottom: 20px;
    }

    .buy__header__back-button {
        position: inherit;
        left: 0;
        top: 0;
        height: 24px;
        width: 24px;
    }

    .buy__header__back-button__img {
        height: 24px;
        width: 24px;
    }

    .buy__container {
        margin-left: 0;

        display: flex;
        flex-direction: column-reverse;
    }

    .buy__header__title {
        margin: 0 0 0 12px;
    }

    .buy__header {
        margin: 0 0 40px 0;
        align-items: center;
    }

    .buy__container__left__el__box__text {
        font-size: 20px;
        line-height: 23px;
        margin-bottom: 0;
    }

    .buy__container__left__el__box__button {
        margin-top: 20px;
        margin-bottom: 0;
    }

    .buy__container__left__el__box__button__text {
        font-size: 16px;
        line-height: 19px;
        padding: 0 16px 0 8px;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
    }

    .buy__container__left__el__box__button__img {
        width: 24px;
        height: 24px;
        margin: 4px 0 4px 4px;
    }

    .buy__container__left__el__number {
        position: absolute;
    }

    .buy__container__left__el__box__title {
        margin-left: 32px;
        font-size: 35px;
        line-height: 49px;
    }

    .buy__container__right__wrapper {
        width: 100%;
    }

    .buy__container__right__title {
        font-size: 24px;
        margin-bottom: 15px;
    }

    .buy__container__right__list__el__text {
        font-size: 16px;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
    }

    .buy__container__right__price__money {
        font-size: 28px;
    }

    .buy__container__right {
        margin-bottom: 30px;
    }

    .buy__container__left {
        width: auto;
        display: flex;
        flex-direction: column;
    }

    .buy__container__left__el__pay-button__text {
        padding: 6px 16px 6px 8px;
        font-size: 16px;
        line-height: 19px;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
    }

    .buy__container__left__el__pay-button__img {
        width: 24px;
        height: 24px;
        padding: 0 0 0 4px;
    }

    .buy__container__left__el__pay-button {
        margin: 40px 0 90px 0;
        align-self: center;
    }

    .buy__modal__content__header__text {
        font-size: 24px;
    }

    .buy__modal__content__header__img {
        height: 80px;
        width: 80px;
    }

    .buy__modal__content__info__title {
        font-size: 18px;
    }

    .buy__modal__content__info__list__el__text {
        font-size: 16px;
        padding-top: 3px;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
    }
}



@media (max-width: 370px) {
    .buy__container__left__el__buttons__button__img {
        width: 140px;
    }
}