button {
    background-color: inherit;
    border: none;
}

.dashboard {
    display: flex;
    justify-content: center;
    min-height: 100vh;

    .desktop-dashboard {

        @media screen and (max-width: 850px) {
            display: none !important;
        }
    }

    &__filter-page {
        width: 100%;
        display: none !important;
        flex-direction: column;
        margin: 40px 0;

        &.open {
            display: flex !important;
        }

        &__header {
            width: 100%;
            display: flex;
            justify-content: space-between;

            &__title {
                font-family: 'Roboto';
                font-weight: 400;
                font-size: 28px;
                line-height: 120%;
                letter-spacing: -0.023em;
                color: #0B0A0A;
            }

            &__button {
                cursor: pointer;
            }
        }

        &__box {
            margin-top: 30px;
            display: flex;
            flex-direction: column;
            gap: 24px;

            &__el {
                display: flex;
                flex-direction: column;
                gap: 16px;

                &__title {
                    white-space: nowrap;
                    font-weight: 400;
                    font-size: 16px;
                    font-family: 'Roboto', sans-serif;
                    line-height: 19px;
                    color: #56514D;
                    padding: 1rem 4.1rem 1rem 0;

                    background-image: url('./../images/profile/arrow-down-dashboard.svg');
                    background-repeat: no-repeat;
                    background-position: calc(100% - 1.5rem);
                    background-size: 1.71rem;

                    &__box {
                        align-self: flex-start;
                    }
                }

                &__content {
                    &.numbers {
                        display: flex;
                        gap: 11px;
                    }

                    &__first,
                    &__second {
                        display: flex;
                        align-items: center;
                        font-weight: 400;
                        font-size: 1.71rem;
                        font-family: 'Roboto', sans-serif;
                        line-height: 2.1rem;
                        color: black;

                        @media (max-width: 850px) {
                            font-size: 15px;
                        }

                        &__input {
                            text-align: center;
                            color: $black;
                            width: 6rem;
                            height: 3.42rem;
                            margin-left: 0.7rem;
                            border: 1px solid #BCB7B3;
                            border-radius: 4px;
                            font-weight: 400;
                            font-size: 1.71rem;
                            font-family: 'Roboto', sans-serif;
                            line-height: 2.1rem;
                            color: $black;

                            @media (max-width: 850px) {
                                width: 70px;
                                height: 35px;
                                font-size: 15px;
                            }

                            &::placeholder {
                                color: #BCB7B3;
                            }

                            &:focus {
                                outline: none;
                            }

                            &::-webkit-outer-spin-button,
                            &::-webkit-inner-spin-button {
                                -webkit-appearance: none;
                                margin: 0;
                            }
                        }


                    }


                    input[type="checkbox"]:checked,
                    input[type="checkbox"]:not(:checked) {
                        position: absolute;
                        z-index: -1;
                        opacity: 0;
                    }

                    input[type="checkbox"]:checked+label,
                    input[type="checkbox"]:not(:checked)+label {
                        display: inline-flex;
                        align-items: center;
                        user-select: none;
                    }

                    input[type="checkbox"]:not(:checked)+label::before {
                        content: '';
                        display: inline-block;
                        width: 20px;
                        height: 20px;
                        flex-shrink: 0;
                        flex-grow: 0;
                        border: 1px solid #BCB7B3;
                        border-radius: 3px;
                        margin-right: 0.85rem;
                        background-repeat: no-repeat;
                        background-size: 16px;
                        background-position: center center;
                        transition: all .3s;
                    }

                    input[type="checkbox"]:hover+label::before {
                        border: 1px solid #BCB7B3;
                        border-radius: 3px;
                        background-image: url('./../images/profile/checkbox-hover.svg');
                        background-size: 16px;
                        background-position: center center;
                    }

                    input[type="checkbox"]:checked+label::before {
                        content: '';
                        display: inline-block;
                        width: 20px;
                        height: 20px;
                        flex-shrink: 0;
                        flex-grow: 0;
                        border-radius: 3px;
                        margin-right: 0.85rem;
                        background-repeat: no-repeat;
                        background-position: center center;
                        border: 1px solid #E75549;
                        border-radius: 3px;
                        background-image: url('./../images/profile/checkbox-checked.svg');
                        background-size: 16px;
                    }

                    &__el {
                        display: flex;
                        margin-top: 12px;

                        &:first-child {
                            margin-top: 0;
                        }

                        &__input {
                            cursor: pointer;
                            width: 20px;
                            height: 20px;
                        }

                        &__text {
                            font-family: 'Roboto';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 19px;
                            color: #0B0A0A;
                        }
                    }
                }
            }
        }

        &__button {
            margin-top: 30px;
            background: #E75549;
            border-radius: 50px;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            text-transform: uppercase;
            color: #FDFAFA;
            padding: 6px 16px 6px 36px;
            border: none;
            margin-bottom: 100px;
            background-image: url("./../images/profile/mobile-filter-arrow.svg");
            background-repeat: no-repeat;
            background-position: 0.35rem;
            background-size: 24px;
            align-self: center;
        }
    }

    &__table {
        display: flex;
        flex-direction: column;
        width: 100%;

        &.mobile.close {
            display: none !important;
        }

        &__mobile-row {
            width: 100%;
            padding: 16px 0;
            border-top: 1px solid #E4E3E2;
            border-bottom: 1px solid #E4E3E2;
            display: flex;
            flex-direction: column;
            gap: 16px;

            &:first-child {
                border-top: none;
            }

            &:last-child {
                border-bottom: none;
            }

            &__title {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;

                &__left {
                    display: flex;
                    align-items: center;
                    gap: 8px;

                    &__text {
                        display: flex;
                        flex-direction: column;
                        gap: 4px;

                        &__title {
                            font-family: 'Roboto';
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 19px;
                            color: #0B0A0A;
                        }

                        &__value {
                            font-family: 'Roboto';
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 16px;
                            color: #BCB7B3;
                        }

                        &__descr {
                            font-family: 'Roboto';
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 16px;
                            color: #56514D;
                        }
                    }
                }
            }

            &__content {
                margin-left: 26px;
                gap: 12px;
                display: flex;
                flex-direction: column;
                width: calc(100vw - 40px - 26px);

                &__el {
                    display: grid;
                    gap: 10px;
                    grid-template-columns: 20px auto;

                    &.button {
                        cursor: pointer;
                    }

                    &__img {
                        width: 20px;
                        height: 20px;
                    }

                    &__text {
                        font-family: 'Roboto';
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 19px;
                        color: #56514D;
                        word-break: break-word;
                        white-space: pre-wrap;
                        text-align: start;

                        &.button {
                            color: #BCB7B3;
                        }

                        &.box {
                            width: 90%;
                        }
                    }
                }
            }
        }
    }

    &__load-more-button {
        margin-top: 3.5rem;
        cursor: pointer;
        display: flex;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        gap: 0.56rem;

        @media screen and (max-width: 850px) {
            gap: 8px;
        }

        &.hidden {
            display: none;
        }

        &__img {
            height: 1.71rem;
            width: 1.71rem;

            @media screen and (max-width: 850px) {
                width: 24px;
                height: 24px;
            }
        }

        &__text {
            font-family: 'Roboto', sans-serif;
            font-weight: 400;
            font-size: 1.71rem;
            line-height: 2rem;
            color: #56514D;

            @media screen and (max-width: 850px) {
                font-size: 16px;
            }
        }
    }

    &__content {
        margin-bottom: 5rem;
    }

    &__wrapper {
        max-width: 1920px;
        width: 100%;
        padding: 8.56rem 6.42rem 0;
        display: flex;
        flex-direction: column;

        @media screen and (max-width: 850px) {
            padding: 50px 20px 0;
            white-space: nowrap;
        }
    }

    &-filters {
        gap: 4.3rem;

        @media screen and (max-width: 850px) {
            padding: 2px 0;

            button:first-child {
                margin-left: 20px;
            }

            button:last-child {
                margin-right: 20px;
            }
        }
    }

    &__main-title {
        font-family: 'Roboto', sans-serif;
        font-weight: 600;
        font-size: 8.21rem;
        line-height: 8.56rem;
        letter-spacing: -0.02em;
        color: $black;

        @media screen and (max-width: 850px) {
            margin-bottom: 40px;
            font-size: 28px;
        }
    }

    &__nav {
        margin-top: 5.56rem;

        @media screen and (max-width: 850px) {
            margin-top: 60px;
            position: absolute;
            width: 100vw;
            left: 0;

            overflow: -moz-scrollbars-none;

            ::-webkit-scrollbar {
                width: 0;
            }
        }
    }

    &__text {
        font-weight: 400;
        font-size: 1.71rem;
        line-height: 2.05rem;
        font-family: 'Roboto', sans-serif;
        color: $black;
        text-align: center;
        align-self: center;

        &.link {
            color: #5480A0;
            text-decoration: none;
        }

        &.big {
            text-align: start;
            word-break: break-word;
        }

        &.green {
            color: #14AE5C;
        }

        &.red {
            color: #14AE5C;
        }

        &.grey {
            color: #BCB7B3;
        }
    }

    &__title {
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        font-size: 1.71rem;
        line-height: 2.05rem;
        color: #BCB7B3;

        &.bg {
            background-image: url('./../images/dashboard/sort.svg');
            background-repeat: no-repeat;
            background-size: 1.71rem;
            background-position: 100%;
            padding: 0 2.56rem;
            cursor: pointer;

            &.actived {
                color: #56514D;
                background-image: url('./../images/dashboard/sort-active.svg');
            }

            &.reverse {
                color: #56514D;
                background-image: url('./../images/dashboard/sort-reverse.svg');
            }
        }

        &.bgfirst {
            background-image: url('./../images/dashboard/sort.svg');
            background-repeat: no-repeat;
            background-position: 100% center;
            padding-right: 2.56rem;
            background-size: 1.71rem;
            cursor: pointer;

            &.actived {
                color: #56514D;
                background-image: url('./../images/dashboard/sort-active.svg');
            }

            &.reverse {
                color: #56514D;
                background-image: url('./../images/dashboard/sort-reverse.svg');
            }
        }
    }

    &__add-button {
        display: flex;
        padding: 0.35rem 1.36rem 0.35rem 4.45rem;
        background: #E75549;
        border-radius: 50px;
        border: none;
        font-weight: 400;
        font-size: 1.54rem;
        line-height: 2.4rem;
        letter-spacing: -0.03em;
        text-transform: uppercase;
        color: $white;
        background-repeat: no-repeat;
        background-position: 0.35rem;
        background-image: url('./../images/dashboard/plus.svg');
        align-self: center;
        align-items: center;
        margin: 3.42rem 0 4.28rem;
        cursor: pointer;
        background-size: 3.42rem;
        height: 4.1rem;

        &.close {
            display: none;
        }
    }

    &__filter {
        display: flex;
        gap: 2.56rem;
        margin: 2.15rem 0 2.56rem;
        width: 100%;

        @media screen and (max-width: 850px) {
            gap: 16px;
            margin: 0 0 24px;
        }

        &.mobile.close {
            display: none !important;
        }

        &__mobile-button {
            width: 24px;
            height: 24px;
            align-self: center;
            cursor: pointer;

            &__img {
                width: 24px;
                height: 24px;
            }
        }

        &__search-box {
            position: relative;
            width: 100%;

            &.small {
                display: none;
            }

            &.big {
                display: block;
            }

            @media (max-width: 1700px) {
                &.big {
                    display: none;
                }

                &.small {
                    display: block;
                    margin-top: 2.56rem;
                }
            }

            &__search {
                width: 100%;
                border: 1px solid #BCB7B3;
                border-radius: 30px;
                font-weight: 400;
                font-size: 1.71rem;
                font-family: 'Roboto', sans-serif;
                line-height: 2.1rem;
                padding: 1rem;
                padding-left: 4.28rem;

                @media screen and (max-width: 850px) {
                    padding-left: 38px;
                    font-size: 16px;
                    line-height: 19px;
                }

                &::placeholder {
                    color: #BCB7B3;
                }

                &:focus {
                    outline: none;
                    border-color: $black;

                    &::placeholder {
                        color: white;
                    }
                }
            }

            &__search:focus~&__img {
                background-image: url('./../images/profile/search-black.svg');
            }

            &__img {
                position: absolute;
                left: 1.71rem;
                top: 1.11rem;
                height: 1.71rem;
                width: 1.71rem;
                background-image: url('./../images/profile/search.svg');
                background-repeat: no-repeat;

                @media screen and (max-width: 850px) {
                    width: 20px;
                    height: 20px;
                }
            }
        }

        &__box {
            position: relative;
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;

            &__checkbox {
                display: none;
            }

            &__checkbox:checked~&__content {
                transform: scaley(1);
                z-index: 100;
            }

            &__content {
                transform: scaley(0);
                transform-origin: left top;
                transition: 0.1s;
                position: absolute;
                display: flex;

                &.right {
                    right: 0;
                }

                &.calendar {
                    background: none;
                    align-items: center;
                    left: -200px;

                    @media (max-width: 1200px) {
                        left: -140px;
                    }
                }

                &.calendar-box {
                    background-color: white;
                    padding: 1.71rem 0;

                    &>.calendar {
                        @media (max-width: 1200px) {
                            // transform: scale(0.7) translateY(-75px) translateX(-70px);
                        }
                    }
                }

                &.price {
                    flex-direction: row;
                    left: -11.1rem;
                }

                input[type="checkbox"]:checked,
                input[type="checkbox"]:not(:checked) {
                    position: absolute;
                    z-index: -1;
                    opacity: 0;
                }

                input[type="checkbox"]:checked+label,
                input[type="checkbox"]:not(:checked)+label {
                    display: inline-flex;
                    align-items: center;
                    user-select: none;
                }

                input[type="checkbox"]:not(:checked)+label::before {
                    content: '';
                    display: inline-block;
                    width: 20px;
                    height: 20px;
                    flex-shrink: 0;
                    flex-grow: 0;
                    border: 1px solid #BCB7B3;
                    border-radius: 3px;
                    margin-right: 0.85rem;
                    background-repeat: no-repeat;
                    background-size: 16px;
                    background-position: center center;
                }

                input[type="checkbox"]:hover+label::before {
                    border: 1px solid #BCB7B3;
                    border-radius: 3px;
                    background-image: url('./../images/profile/checkbox-hover.svg');
                    background-size: 16px;
                    background-position: center center;
                }

                input[type="checkbox"]:checked+label::before {
                    content: '';
                    display: inline-block;
                    width: 20px;
                    height: 20px;
                    flex-shrink: 0;
                    flex-grow: 0;
                    border-radius: 3px;
                    margin-right: 0.85rem;
                    background-repeat: no-repeat;
                    background-position: center center;
                    border: 1px solid #E75549;
                    border-radius: 3px;
                    background-image: url('./../images/profile/checkbox-checked.svg');
                    background-size: 16px;
                }

                &__el {
                    display: flex;
                    margin-top: 1.05rem;

                    &:first-child {
                        margin-top: 0;
                    }

                    &__input {
                        cursor: pointer;
                        width: 1.71rem;
                        height: 1.71rem;
                    }

                    &__text {
                        white-space: nowrap;
                        font-weight: 400;
                        font-size: 1.71rem;
                        font-family: 'Roboto', sans-serif;
                        line-height: 2.1rem;
                        color: $black;
                        margin-left: 0.85rem;
                        cursor: pointer;

                        &.calendar {
                            margin-left: 2.56rem;
                        }
                    }
                }
            }

            &__checkbox:checked~&__title>&__title__text {
                color: $black;
                background-image: url('./../images/profile/arrow-up.svg');
            }

            &__checkbox:checked~&__title {
                border-color: $black;
            }

            &__title {
                display: flex;
                cursor: pointer;
                border: 1px solid #BCB7B3;
                border-radius: 30px;

                &__text {
                    white-space: nowrap;
                    font-weight: 400;
                    font-size: 1.71rem;
                    font-family: 'Roboto', sans-serif;
                    line-height: 2.1rem;
                    color: #BCB7B3;
                    padding: 1rem 4.1rem 1rem 1.71rem;

                    background-image: url('./../images/profile/arrow-down.svg');
                    background-repeat: no-repeat;
                    background-position: calc(100% - 1.5rem);
                    background-size: 1.71rem;
                }

                &__img {
                    padding-right: 1.05rem;
                }
            }
        }

        &__box__content {
            top: 5.15rem;
            background: $white;
            border-radius: 8px;
            justify-content: space-around;
            cursor: auto;
            padding: 1.71rem;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            &__second {
                margin-left: 0.85rem;
            }

        }
    }
}