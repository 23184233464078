.bookings {
    &__table {

        &__row {
            display: grid;
            grid-template-columns: 180fr 380fr 200fr 180fr 170fr 120fr;
            gap: 8.56rem;
            padding: 1.71rem 0 2.56rem;
            border-top: 1px solid #BCB7B3;

            &:first-child {
                border: none;
            }

            &:nth-child(2) {
                border: none;
            }
        }

        &__title {
            display: grid;
            grid-template-columns: 180fr 380fr 200fr 180fr 170fr 120fr;
            gap: 8.56rem;
            margin: 2.56rem 0 0.9rem;


            // @media (max-width: 1450px) {
            //     gap: 50px;
            // }

            // @media (max-width: 1200px) {
            //     gap: 30px;
            // }

            &__el {
                justify-self: center;
                white-space: nowrap;
            }
        }
    }
}