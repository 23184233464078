.footer {
    height: 7.27rem;
    margin: 0 6.85rem 4.3rem 6.85rem;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid $black;

    @media (max-width: 850px) {
        flex-direction: column-reverse;
        height: auto !important;
        margin: 0 18px 4.3rem 18px;
    }

    &.alternitive {
        width: calc(100% - 13.69rem);
        border-top: 1px solid $white;
        position: absolute;
        bottom: 0px;
        z-index: 100;

        @media (max-width: 850px) {
            width: calc(100% - 20px - 18px);
        }
    }

    &__left {
        margin-top: 4.3rem;
        display: flex;
        flex-direction: column;

        @media (max-width: 850px) {
            margin-top: 30px !important;
            gap: 6px;
        }

        &__text {
            font-weight: 400;
            font-size: 1.71rem;
            font-family: 'Roboto', sans-serif;
            line-height: 2rem;
            color: $black;
            align-self: flex-end;

            @media (max-width: 850px) {
                align-self: flex-start;
                font-size: 13px;
            }

            &.alternitive {
                color: $white;
            }

            &__small {
                height: 2.75rem;
                margin-left: -0.35rem;

                @media (max-width: 850px) {
                    margin-top: 0;
                    height: 30px;
                    margin-left: -4px;
                }
            }
        }
    }

    &__right {
        display: flex;
        justify-content: space-between;
        margin-top: 4.3rem;

        @media (max-width: 1100px) {
            margin-top: 0;
        }

        @media (max-width: 850px) {
            flex-direction: column;
            align-self: flex-start;
            margin-top: 0;
            gap: 10px;
        }

        &__text {
            font-family: 'Roboto', sans-serif;
            font-weight: 400;
            font-size: 1.71rem;
            line-height: 2rem;
            text-decoration: none;
            color: $black;
            transition: all .3s;

            &:hover {
                opacity: 0.8;
            }

            @media (max-width: 850px) {
                font-size: 15px;
            }

            &.alternitive {
                color: $white;
            }

            &.second {
                margin-left: 4.3rem;

                @media (max-width: 850px) {
                    margin-left: 0;

                }
            }
        }
    }
}

@media (max-width: 1100px) {
    .footer {
        padding-top: 20px;
    }

    .footer__left {
        margin-top: 0;
    }

    .footer__left__logo {
        padding-top: 27px;
        width: 100px;
    }
}

@media (max-width: 600px) {

    .footer,
    .footer.alternitive {
        margin: 0 5vw 20px 5vw;
        width: 90%;
    }
}