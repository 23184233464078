.notFound {
    width: calc(100vw);
    height: calc(100vh - 6.84rem - 10px);
    margin-bottom: 40px;

    &__wrapper {
        width: calc(100vw - 20px);
        height: calc(100vh - 6.84rem - 10px);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: #E75549;
        border-radius: 30px;
        margin: 2.56rem 1rem 1rem 1rem;
    }

    &__title {
        font-weight: 600;
        font-size: 25.6rem;
        line-height: 30.8rem;
        letter-spacing: -0.02em;
        color: $white;

        @media (max-width: 850px) {
            font-size: 100px;
        }
    }

    &__text {
        font-weight: 400;
        font-size: 4.62rem;
        font-family: 'Roboto', sans-serif;
        line-height: 120%;
        letter-spacing: -0.021em;
        color: $white;

        @media (max-width: 850px) {
            font-size: 28px;
        }
    }

    &__button {
        display: flex;
        border: none;
        align-items: center;
        margin-top: 50px;
        background: $white;
        border-radius: 50px;
        cursor: pointer;

        &__img {
            padding: 4px;
            height: 3.42rem;
            width: 3.42rem;

            @media (max-width: 850px) {
                height: 24px;
                width: 24px;
            }
        }

        &__text {
            font-weight: 400;
            font-size: 1.71rem;
            font-family: 'Roboto', sans-serif;
            line-height: 2.05rem;
            text-transform: uppercase;
            color: $black;
            padding: 0 1.36rem 0 0.8rem;

            @media (max-width: 850px) {
                font-size: 14px;
            }
        }
    }
}
