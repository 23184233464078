.terms {
    padding: 4.3rem 6.42rem;
    display: grid;
    grid-template-columns: 18rem 1fr;
    gap: 8.56rem;

    &__mobile-button {
        display: none;
        position: fixed;
        right: 1.36rem;
        bottom: 6rem;

        height: 5.13rem;
        width: 5.13rem;
        border-radius: 100%;
        background-color: $white;
        box-shadow: 0px 0.35rem 1.71rem rgba(79, 79, 79, 0.2);
        padding: 1.71rem;
        
        @media (max-width: 850px) {
            display: block;
        }
    }

    @media (max-width: 850px) {
        grid-template-columns: 100%;
        gap: 0;
        padding: 50px 20px;
    }

    &__nav {
        position: fixed;

        @media (max-width: 850px) {
            position: relative;
            flex-direction: row;
            gap: 12px;
            overflow-x: scroll;
            width: 100%;
            -ms-overflow-style: none;
            overflow: -moz-scrollbars-none;

            ::-webkit-scrollbar {
                width: 0;
            }
        }

        &__container {
            display: flex;
            flex-direction: column;
            gap: 16px;

            @media (max-width: 850px) {
                flex-direction: row;
                gap: 12px;
                overflow-x: scroll;
                padding: 2px 0;

                ::-webkit-scrollbar {
                    width: 0;
                }
            }
        }

        &__el {
            font-weight: 400;
            font-size: 20px;
            line-height: 30px;
            text-transform: uppercase;
            color: $black;
            text-decoration: none;

            @media (max-width: 850px) {
                font-size: 13px;
                line-height: 21px;
                white-space: nowrap;
                padding: 4px 16px;
                border-radius: 30px;
                border: none;
                background-color: white;
            }

            &.chosen {
                @media (max-width: 850px) {
                    color: $white;
                    background-color: #E75549;
                }
            }
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: 2.56rem;

        &__title {
            font-family: 'Roboto', sans-serif;
            font-weight: 400;
            font-size: 4.65rem;
            line-height: 5.55rem;
            letter-spacing: -2.1%;

            @media (max-width: 850px) {
                font-size: 28px;
                line-height: 33.6px;
            }
        }

        &__subtitle {
            font-weight: 400;
            font-size: 3rem;
            line-height: 4.2;

            @media (max-width: 850px) {
                font-size: 22px;
                line-height: 26px;
            }
        }

        &__text {
            font-family: 'Roboto', sans-serif;
            font-weight: 400;
            font-size: 1.71rem;
            line-height: 2rem;
            white-space: pre-line;

            @media (max-width: 850px) {
                font-size: 16px;
                line-height: 18.75px;
            }
        }
    }
}