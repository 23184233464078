* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Oswald', sans-serif;
    font-style: normal;
}

a {
    text-decoration: none;
}

p,
a,
div,
span,
button,
h1,
h2,
h3,
h4,
h5,
font {
    &::selection {
        color: #FFF;
        background-color: #E75549;
    }
}

body {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    overflow-x: hidden;
    position: relative;
}

body::-webkit-scrollbar {
    width: 4px;
}

body::-webkit-scrollbar-track {
    background: #E4E3E2;
}

body::-webkit-scrollbar-thumb {
    background-color: #E75549;
    border-radius: 20px;
}

main {
    flex-grow: 1;
    min-height: calc(100vh - 130px - 85px);
    margin-top: 6.84rem;
    z-index: 1;
}

html {
    font-size: 10px;
}

@media only screen and (max-width: 1300px) {
    html {
        font-size: 8.96px;
    }
}

@media only screen and (max-width: 1200px) {
    html {
        font-size: 8.36px;
    }
}

@media only screen and (max-width: 1100px) {
    html {
        font-size: 8.04px;
    }
}

@media only screen and (max-width: 900px) {
    html {
        font-size: 7.68px;
    }
}

@media only screen and (max-width: 800px) {
    html {
        font-size: 7.36px;
    }
}

@media only screen and (max-width: 700px) {
    html {
        font-size: 7.04px;
    }
}

@media only screen and (max-width: 680px) and (min-height: 800px) {
    html {
        font-size: 8px;
    }
}

@media only screen and (max-width: 600px) {
    html {
        font-size: 7.5px;
    }
}

@media only screen and (max-width: 450px) {
    html {
        font-size: 7.1px;
    }
}

@media only screen and (max-width: 350px) {
    html {
        font-size: 6.56px;
    }
}

@media only screen and (max-width: 1000px) and (max-height: 520px) {
    html {
        font-size: 7px;
    }
}

@media only screen and (min-width: 1650px) {
    html {
        font-size: 11.68px;
    }
}

@media only screen and (min-width: 2400px) {
    html {
        font-size: 12.5px;
    }
}

.wrap {
    width: 1470px;
    margin: 0 auto;
}

.desktop {
    display: flex !important;

    @media (max-width: 850px) {
        display: none !important;
    }
}

.mobile {
    display: none !important;

    @media (max-width: 850px) {
        display: flex !important;
    }
}

.hidden {
    position: absolute;
    left: -9999999999px;
    width: 1px;
    height: 1px;
    opacity: 0;
}

.slick-initialized .slick-slide>div>div {
    margin-bottom: 0;
    box-shadow: none;
    height: 900px;
    width: 90% !important;
    margin-bottom: 0 !important;

    @media (max-width: 850px) {
        height: 700px;
    }
}

@media (max-width: 1600px) {
    .wrap {
        width: 1250px;
    }
}


@media (max-width: 1450px) {
    .wrap {
        width: 1100px;
    }
}

@media (max-width: 1200px) {
    .wrap {
        width: 900px;
    }
}

@media (max-width: 1000px) {
    .wrap {
        width: 800px;
    }
}

@media (max-width: 850px) {
    .wrap {
        width: 100%;
    }
}