.subscribes {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 4.3rem 0 9.4rem 0;

    &__title {
        font-size: 4.6rem;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        line-height: 120%;
        letter-spacing: -0.021em;
        color: #BCB7B3;
        text-align: center;
    }

    &__button {
        text-decoration: none;
        margin-top: 2.15rem;
        display: flex;
        align-items: center;
        background: #E75549;
        border-radius: 50px;
        border: none;
        color: $white;
        font-size: 1.54rem;
        line-height: 2.05rem;
        cursor: pointer;

        &__img {
            padding: 0.35rem;
            height: 3.43rem;
            width: 3.43rem;
        }

        &__text {
            padding: 0 1.36rem 0 0.85rem;
            text-transform: uppercase;
        }
    }

    &__suggestions {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 2.15rem;
        margin-top: 8.56rem;

        &__card:nth-child(1)>&__card__header {
            background-color: #FFCF78;
        }

        &__card:nth-child(2)>&__card__header {
            background-color: #F9B274;
        }

        &__card:nth-child(3)>&__card__header {
            background-color: #F29270;
        }

        &__card:first-child>&__card__header>&__card__wrapper>&__card__header__text>&__card__header__text__price {
            color: $black
        }

        &__card:first-child>&__card__header>&__card__wrapper>&__card__header__text>&__card__header__text__month {
            color: #56514D;
        }

        &__card {
            width: 100% !important;
            box-shadow: 0px 2.05rem 4.1rem rgba(79, 79, 79, 0.1);
            border-radius: 30px;
            background: $white;

            &__wrapper {
                padding: 2.56rem;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }

            &__header {
                width: 100%;
                box-shadow: 0px 2.15rem 3.85rem rgba(79, 79, 79, 0.07);
                border-radius: 30px;
                display: flex;

                &__container {
                    display: flex;
                    align-items: center;
                }

                &__title {
                    width: auto;
                    white-space: nowrap;
                    padding: 0.51rem 1.3rem;
                    font-size: 2.05rem;
                    line-height: 2.5rem;
                    background: $white;
                    box-shadow: 0px 0px 1.3rem rgba(79, 79, 79, 0.08);
                    border-radius: 50px;
                    text-transform: uppercase;
                    align-self: flex-start;

                    color: $black;

                    &__text {
                        margin-left: 1rem;
                        font-size: 1.36rem;
                        line-height: 1.62rem;
                        color: $white;
                        font-family: 'Roboto', sans-serif;
                        font-weight: 400;
                    }
                }

                &__text {
                    margin: 0.45rem 0 1rem 0;
                    display: flex;
                    align-items: center;

                    &__price {
                        font-size: 4.6rem;
                        font-family: 'Roboto', sans-serif;
                        font-weight: 400;
                        line-height: 120%;
                        letter-spacing: -0.021em;
                        color: $white;
                    }

                    &__month {
                        margin-left: 0.9rem;
                        font-size: 1.71rem;
                        font-family: 'Roboto', sans-serif;
                        line-height: 2.05rem;
                        color: $white;
                    }
                }

                &__button {
                    margin-top: 1.71rem;
                    display: flex;
                    align-items: center;
                    background: $white;
                    border-radius: 50px;
                    border: none;
                    align-self: flex-start;

                    font-size: 1.54rem;
                    line-height: 2.05rem;
                    text-transform: uppercase;
                    color: $black;
                    cursor: pointer;

                    &__text {
                        padding: 0 1.36rem 0 0.35rem;
                    }

                    &__img {
                        padding: 0.35rem;
                        height: 3.42rem;
                        width: 3.42rem;
                    }
                }

            }

            &__body {
                display: flex;
                flex-direction: column;

                &__wrapper {
                    padding: 2.56rem;
                }

                &__el:first-child>&__el__text {
                    padding: 0 0 1.3rem 0;
                    border: none;
                }

                &__el {

                    &__text {
                        font-size: 1.71rem;
                        font-family: 'Roboto', sans-serif;
                        line-height: 2.05rem;
                        color: #56514D;
                        padding: 1.3rem 0;
                        border-top: 1px solid #BCB7B3;
                    }

                }
            }
        }
    }

    &__active {
        margin-left: 2.56rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 4.3rem 0 12.9rem 0;

        &__container {
            display: flex;

            &__mobile__box {
                z-index: 100;
            }
        }

        &__status.actived {
            justify-content: space-between;
        }

        &__status {
            width: 100%;
            display: flex;

            &__box {

                &.hide {
                    box-shadow: none;
                    background-color: inherit;
                    cursor: auto !important;
                    transition: all .3s;

                    @media (max-width: 850px) {
                        display: none;
                    }

                    &:hover {
                        box-shadow: none !important;
                    }
                }

                &__mobileButton {
                    display: none;
                }

                display: flex;
                background: $white;
                box-shadow: 0px 2.91rem 4.53rem rgba(79, 79, 79, 0.07);
                border-radius: 30px;
                width: 36rem;
                height: 17.1rem;
                transition: all .3s;

                &:nth-child(1) {
                    z-index: 100;

                }

                &:nth-child(2) {
                    z-index: 10;
                    animation: rollout11000 1s;
                    cursor: pointer;

                    &:hover {
                        box-shadow: 0px 2.15rem 3.85rem rgba(79, 79, 79, 0.1);
                    }
                }

                &:nth-child(3) {
                    z-index: 1;
                    animation: rollout21000 1s;
                    cursor: pointer;

                    &:hover {
                        box-shadow: 0px 2.15rem 3.85rem rgba(79, 79, 79, 0.1);
                    }
                }

                &__wrapper {
                    padding: 2.56rem;
                }

                &__title {
                    font-size: 2.05rem;
                    line-height: 2.5rem;
                    text-transform: uppercase;
                    color: $black;
                    background: $white;
                    box-shadow: 0px 0px 1.3rem rgba(79, 79, 79, 0.08);
                    border-radius: 50px;
                    padding: 0.51rem 1.3rem;
                }

                &__header {
                    display: flex;
                    align-items: center;
                }

                &__descr {
                    font-size: 1.36rem;
                    line-height: 1.62rem;
                    color: #BCB7B3;
                    margin-top: 0.45rem;
                    font-family: 'Roboto', sans-serif;
                    font-weight: 400;
                }

                &__price {
                    display: flex;
                    align-items: center;

                    &__money {
                        margin: 0.45rem 0.9rem 0 0;
                        font-size: 4.6rem;
                        font-family: 'Roboto', sans-serif;
                        font-weight: 400;
                        line-height: 120%;
                        letter-spacing: -0.021em;
                        color: $black;
                    }

                    &__monthly {
                        font-size: 1.71rem;
                        font-family: 'Roboto', sans-serif;
                        line-height: 2.05rem;
                        color: #56514D;
                    }
                }
            }

            &__button {
                display: flex;
                border: none;
                background: white;
                align-items: center;
                margin-left: 2.56rem;
                cursor: pointer;

                &__text {
                    margin-left: 0.85rem;
                    font-size: 1.54rem;
                    line-height: 2.05rem;
                    text-transform: uppercase;
                    color: #56514D;

                    @media (max-width: 850px) {
                        font-size: 14px;
                        line-height: 20px;
                    }
                }

                &__img {
                    height: 2.5rem;
                    width: 2.5rem;

                    @media (max-width: 850px) {
                        height: 24px;
                        width: 24px;
                    }
                }
            }
        }

        &__cancelButton {
            padding-top: 3px;
            margin-top: 1.71rem;
            margin-left: 8.56rem;
            display: flex;
            border: none;
            background-color: white;
            cursor: pointer;
            align-self: flex-start;

            &__text {
                font-size: 1.54rem;
                line-height: 2.05rem;
                color: #BCB7B3;
                margin-left: 0.51rem;
                font-family: 'Roboto', sans-serif;
            }
        }


        &__title {
            margin: 4.3rem 0;
            font-size: 4.6rem;
            font-family: 'Roboto', sans-serif;
            font-weight: 400;
            line-height: 120%;
            letter-spacing: -0.021em;
            color: $black;
        }

        &__my {
            width: 64.21rem;
            display: flex;
            flex-direction: column;

            &__el {
                width: 100%;
                display: flex;
                justify-content: space-between;
                border-top: 1px solid #BCB7B3;
                padding: 1.3rem 0;
                cursor: pointer;

                &:first-child {
                    border: none;
                    padding-top: 0;
                }

                &__text {
                    font-size: 1.71rem;
                    font-family: 'Roboto', sans-serif;
                    line-height: 2.05rem;
                    color: #56514D;
                }
            }
        }

        &__modal {

            &__content {
                padding: 2.56rem 6.51rem;

                &__header {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    margin-bottom: 2.15rem;

                    &__img {
                        margin-bottom: 1.3rem;
                        align-self: center;
                    }

                    &__text {
                        font-size: 3.42rem;
                        line-height: 4.1rem;
                        color: #E75549;
                    }
                }

                &__info {

                    &__text {
                        margin-bottom: 2.56rem;
                        font-size: 1.71rem;
                        font-family: 'Roboto', sans-serif;
                        line-height: 2.05rem;
                        text-align: center;
                        color: #56514D;
                        white-space: pre-line;
                    }
                }

                &__button {
                    display: flex;
                    align-items: center;
                    background-color: #E75549;
                    border-radius: 50px;
                    border: none;

                    font-size: 1.54rem;
                    line-height: 2.05rem;
                    text-transform: uppercase;
                    color: $black;
                    cursor: pointer;

                    &__text {
                        padding: 0 1.36rem 0 0.35rem;
                        color: $white;
                    }

                    &__img {
                        padding: 0.35rem;
                        height: 3.42rem;
                        width: 3.42rem;
                    }
                }

                &__cancelButton {
                    margin-top: 1.3rem;
                    align-items: center;
                    display: flex;
                    border: none;
                    background-color: white;
                    cursor: pointer;

                    @media (max-width: 850px) {
                        margin: 15px auto 0 auto;
                    }

                    &__text {
                        font-size: 1.54rem;
                        line-height: 2.05rem;
                        color: #BCB7B3;
                        margin-left: 0.51rem;
                    }

                    &__img {
                        padding-top: 3px;
                    }
                }
            }

            &__endSub {
                &__content {
                    padding: 2.56rem 6.51rem;

                    &__text {
                        font-family: 'Roboto', sans-serif;
                        font-weight: 400;
                        font-size: 1.71rem;
                        line-height: 2rem;
                        text-align: center;
                        margin: 1.3rem 0 2.56rem 0;
                        color: #56514D;
                        max-width: 26rem;
                    }

                    &__header {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        margin-bottom: 1.3rem;

                        &__img {
                            margin-bottom: 1.3rem;
                            align-self: center;
                        }

                        &__text {
                            font-size: 3.42rem;
                            line-height: 4.1rem;
                            color: $black;
                        }
                    }

                    &__info {

                        &__text {
                            margin-bottom: 2.56rem;
                            font-size: 1.71rem;
                            font-family: 'Roboto', sans-serif;
                            line-height: 2.05rem;
                            text-align: center;
                            color: #56514D;
                            white-space: pre-line;
                        }
                    }

                    &__button.width {
                        width: auto;
                    }

                    &__button {
                        display: flex;
                        align-items: center;
                        background-color: #E75549;
                        border-radius: 50px;
                        border: none;

                        font-size: 1.54rem;
                        line-height: 2.05rem;
                        text-transform: uppercase;
                        color: $black;
                        cursor: pointer;
                        margin-bottom: 1.3rem;

                        &__text {
                            padding: 0 1.36rem 0 0.35rem;
                            color: $white;
                        }

                        &__img {
                            padding: 0.35rem;
                            height: 3.42rem;
                            width: 3.42rem;
                        }
                    }

                    &__cancelButton {
                        display: flex;
                        align-items: center;
                        background-color: $black;
                        border-radius: 50px;
                        border: none;

                        font-size: 1.54rem;
                        line-height: 2.05rem;
                        text-transform: uppercase;
                        color: $black;
                        cursor: pointer;

                        &__text {
                            padding: 0 1.36rem 0 0.35rem;
                            color: $white;
                        }

                        &__img {
                            padding: 0.35rem;
                            height: 3.42rem;
                            width: 3.42rem;
                        }
                    }

                    &__list {
                        margin: 1.3rem 0 2.56rem 0;
                        width: 100%;

                        &__el {
                            display: flex;
                            margin-bottom: 1.3rem;

                            &__text {
                                font-size: 1.71rem;
                                font-family: 'Roboto', sans-serif;
                                line-height: 2.05rem;
                                color: $black;
                                margin-left: 0.51rem;
                            }
                        }
                    }


                }
            }
        }

    }
}

@keyframes rollout1 {
    0% {
        transform: translateX(calc(-50% - 310px));
    }

    100% {
        transform: none;
    }
}

@keyframes rollout2 {
    0% {
        transform: translateX(calc(-100% - 630px));
    }

    100% {
        transform: none;
    }
}

@media (max-width: 1000px) {
    .subscribes__suggestions {
        flex-direction: column;
        display: flex;
        gap: 0;

        &__card {
            width: 100%;
            margin-bottom: 40px;
        }
    }

    .subscribes__active__status__box:nth-child(2) {
        animation: rollout11000 1s;
    }


    .subscribes__active__status__box:nth-child(3) {
        animation: rollout21000 1s;
    }
}

@media (max-width: 850px) {
    .subscribes__active__status__box {
        height: auto;
    }

    .subscribes {
        margin: 40px 0;
        padding: 0 16px;
    }

    .subscribes__suggestions {
        margin-top: 40px;
    }

    .subscribes__title {
        font-size: 28px;
        line-height: 120%;
    }

    .subscribes__button {
        display: none;
    }

    .subscribes__active {
        padding: 0 16px;
        margin: 40px 0 80px 0;
    }

    .subscribes__active__status {
        flex-direction: column;
    }

    .subscribes__active__container__mobile__box {
        display: flex;
        align-items: center;
    }

    .subscribes__active__status__box {
        margin-bottom: 20px;
        width: 100%;

        &__mobileButton {
            display: block;
            width: 24px;
            height: 24px;
            margin-left: 12px;
        }

        &__wrapper {
            padding: 12px;
        }

        &__title {
            font-size: 16px;
            line-height: 24px;
            padding: 6px 15px;
        }

        &__descr {
            font-size: 14px;
            line-height: 16px;
        }

        &__header {
            height: auto;
        }

        &__price {
            margin-top: 14px;

            &__money {
                font-size: 28px;
                line-height: 120%;
                margin-top: 0;
            }

            &__monthly {
                font-size: 16px;
                line-height: 19px;
            }
        }
    }

    .subscribes__active__cancelButton {
        display: none;
    }

    .subscribes__active__status__button {
        margin: 13px 0 0 0;
        align-self: center;
    }

    .subscribes__active__status__box {
        box-shadow: 0px 34px 53px rgba(79, 79, 79, 0.02);
    }


}

@media (max-width: 850px) {

    .subscribes__active__modal__content,
    .subscribes__active__modal__endSub__content {
        padding: 0;
        width: calc(100vw - 32px);
        padding: 24px 0 40px 0;
    }

    .subscribes__active__modal__endSub__content {
        padding: 24px 16px 40px;
    }

    .subscribes__active__modal__endSub__content__list__el__text {
        padding-top: 2px;
    }

    .subscribes__active__modal__content__header__text,
    .subscribes__active__modal__endSub__content__header__text {
        line-height: 26px;
        font-size: 22px;
    }

    .subscribes__active__modal__content__info__text,
    .subscribes__active__modal__endSub__content__info__text,
    .subscribes__active__modal__endSub__content__list__el__text {
        font-size: 16px;
        line-height: 19px;
    }

    .subscribes__active__modal__endSub__content__button__img,
    .subscribes__active__modal__endSub__content__cancelButton__img,
    .subscribes__active__modal__content__button__img {
        height: 24px;
        width: 24px;
        padding: 0;
        margin: 4px 0 4px 4px;
    }

    .subscribes__active__modal__endSub__content__button__text,
    .subscribes__active__modal__endSub__content__cancelButton__text,
    .subscribes__active__modal__content__button__text {
        font-size: 14px;
        line-height: 21px;
        padding: 0 16px 0 8px;
    }

    .subscribes__active__modal__content__header,
    .subscribes__active__modal__content__info__text,
    .subscribes__active__modal__endSub__content__info__text {
        margin-bottom: 24px;
    }

    .subscribes__active__modal__content__cancelButton__text {
        font-size: 16px;
        margin-left: 6px;
    }

    .subscribes__active__modal__content__cancelButton__img {
        width: 20px;
        height: 20px;
        padding-top: 2px;
    }
}


@media (max-width: 340px) {
    .subscribes__suggestions__card__header__title__text {
        font-size: 12px;
    }

}


@keyframes rollout11200 {
    0% {
        transform: translateX(calc(-50% - 146px));
    }

    100% {
        transform: none;
    }
}

@keyframes rollout21200 {
    0% {
        transform: translateX(calc(-50% - 381px));
    }

    100% {
        transform: none;
    }
}

@keyframes rollout11600 {
    0% {
        transform: translateX(calc(-50% - 204px));
    }

    100% {
        transform: none;
    }
}

@keyframes rollout21600 {
    0% {
        transform: translateX(calc(-50% - 527px));
    }

    100% {
        transform: none;
    }
}

@keyframes rollout11000 {
    0% {
        transform: translateX(calc(-50% - 125px));
    }

    100% {
        transform: none;
    }
}

@keyframes rollout21000 {
    0% {
        transform: translateX(calc(-50% - 363px));
    }

    100% {
        transform: none;
    }
}