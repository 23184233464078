.login {

    &__modal {

        &__content {
            background-color: $white;

            &__wrapper {
                padding: 5.15rem 17.1rem 14.55rem;
                display: flex;
                flex-direction: column;
                align-items: center;

                @media (max-width: 850px) {
                    padding: 40px 43px 100px;
                }
            }

            &__title {
                font-family: 'Roboto', sans-serif;
                font-weight: 600;
                font-size: 8.2rem;
                line-height: 100px;
                letter-spacing: -0.02em;
                color: $black;

                @media (max-width: 850px) {
                    font-size: 47px;
                    line-height: 56px;
                }
            }

            &__connect-box {
                margin-top: 5.5rem;
                display: flex;
                gap: 2.56rem;

                &__el {
                    height: 10.3rem;
                    width: 10.3rem;
                    border-radius: 100%;
                    background: #FDFAFA;
                    box-shadow: 0px 0px 15px rgba(79, 79, 79, 0.08);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transition: all .3s;

                    &:hover {
                        opacity: 0.8;
                        cursor: pointer;
                    }

                    &__img {
                        height: 5.15rem;
                        width: 5.15rem;

                        @media (max-width: 850px) {
                            height: 40px;
                            width: 40px;
                        }
                    }
                }
            }

            &__or {
                text-align: center;
                margin-top: 2.56rem;

                &__text {
                    width: 10.2rem;
                    display: inline-block;
                    vertical-align: middle;
                    font-weight: 400;
                    font-size: 1.71rem;
                    line-height: 2.56rem;
                    text-transform: uppercase;
                    color: $black;

                    @media (max-width: 850px) {
                        font-size: 15px;
                    }

                    &::before,
                    &::after {
                        content: "";
                        display: inline-block;
                        vertical-align: middle;
                        width: 100%;
                        height: 1px;
                        background-color: #BCB7B3;
                        position: relative;
                    }

                    &:before {
                        margin-left: -100%;
                        left: -5px;
                    }

                    &:after {
                        margin-right: -100%;
                        right: -5px;
                    }
                }
            }

            &__choose-number {
                margin-top: 2.56rem;
                font-family: 'Roboto', sans-serif;
                font-weight: 400;
                font-size: 1.71rem;
                line-height: 2rem;
                color: #56514D;

                @media (max-width: 850px) {
                    font-size: 15px;
                }
            }

            &__input {
                margin-top: 1.36rem;
                font-family: 'Roboto', sans-serif;
                border: 1px solid #BCB7B3;
                border-radius: 2.56rem;
                padding: 1.71rem 2.56rem;
                font-weight: 400;
                font-size: 1.71rem;
                line-height: 2rem;
                width: 36rem;
                color: $black;

                @media (max-width: 850px) {
                    font-size: 15px;
                    width: 100%;
                }

                &::-webkit-outer-spin-button,
                &::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }

                &::placeholder {
                    color: #BCB7B3;
                }

                &:focus {
                    outline: none;
                    border: 1px solid $black;
                }

                &.error {
                    border-color: rgba(231, 85, 73, 1);
                    color: rgba(231, 85, 73, 1);
                }
            }

            &__error-text {
                font-family: 'Roboto', sans-serif;
                font-weight: 400;
                font-size: 1.36rem;
                line-height: 1.62rem;
                color: #E75549;
                display: none;
                margin-top: 0.4rem;
                align-self: flex-start;

                @media (max-width: 850px) {
                    font-size: 15px;
                }

                &.error {
                    display: block;
                }
            }

            &__text {
                font-family: 'Roboto', sans-serif;
                margin-top: 1.36rem;
                white-space: pre;
                font-weight: 400;
                font-size: 1.71rem;
                line-height: 2rem;
                text-align: center;
                color: #BCB7B3;

                @media (max-width: 850px) {
                    font-size: 15px;
                }
            }

            &__content-text {
                margin-top: 5.56rem;
                display: flex;
                flex-direction: column;

                @media (max-width: 850px) {
                    gap: 8px;
                    margin-bottom: 20px;
                }

                &__welcome,
                &__descr {
                    font-family: 'Roboto', sans-serif;
                    font-weight: 400;
                    font-size: 1.71rem;
                    line-height: 2rem;
                    text-align: center;
                    color: $black;

                    @media (max-width: 850px) {
                        font-size: 15px;
                    }
                }

                &__descr {
                    white-space: pre;
                    color: #56514D;
                }
            }

            &__button-next {
                margin-top: 2.56rem;
                background: #E75549;
                border-radius: 50px;
                padding: 1rem 1.35rem 1rem 0.35rem;
                font-weight: 400;
                font-size: 1.54rem;
                line-height: 2.4rem;
                letter-spacing: -0.03em;
                text-transform: uppercase;
                color: #FDFAFA;
                height: 4.1rem;
                border: none;
                cursor: pointer;

                display: flex;
                align-items: center;
                gap: 0.85rem;

                &__img {
                    height: 3.42rem;
                    width: 3.42rem;

                    @media (max-width: 850px) {
                        height: 24px;
                        width: 24px;
                    }
                }

                @media (max-width: 850px) {
                    font-size: 13px;
                }
            }

            &__skip {
                font-family: 'Roboto', sans-serif;
                font-style: normal;
                font-weight: 400;
                font-size: 1.71rem;
                line-height: 2rem;
                color: #BCB7B3;
                border: none;
                margin-top: 1.3rem;
                background-color: $white;
                cursor: pointer;

                display: flex;
                align-items: center;
                gap: 0.6rem;

                &__img {
                    height: 2rem;
                    width: 2rem;
                }

                @media (max-width: 850px) {
                    font-size: 15px;
                }
            }
        }
    }
}