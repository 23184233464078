.login-popup {
    background-color: #FDFAFA;
    width: 72rem;
    height: fit-content;
    padding: 5.14rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 5.58rem;

    @media screen and (max-width: 850px) {
        width: 100%;
        justify-content: center;
        row-gap: 20px;
        height: 100%;
    }

    &__topPart {
        display: flex;
        align-items: center;
        flex-direction: column;
        row-gap: 1.37rem;

        @media screen and (max-width: 850px) {
            row-gap: 4px;
        }

        &__emailsent {
            color: var(--success, #14AE5C);
            /* Subheader */
            font-family: Oswald;
            font-size: 2.99658rem;
            font-style: normal;
            font-weight: 400;
            line-height: 4.19521rem; /* 140% */

            @media screen and (max-width: 850px) {
                font-size: 36px;
                line-height: 140%;
            }
        }

        &__svg {
            width: 10.27397rem;
            height: 10.27397rem;

            @media screen and (max-width: 850px) {
                width: 120px;
                height: 120px;
            }
        }

        &__title {
            display: flex;
            align-items: center;
            column-gap: 1.37rem;

            @media screen and (max-width: 850px) {
                column-gap: 16px;
            }

            &__button {
                width: 3.05479rem;
                height: 3.05479rem;
                background-color: #0B0A0A;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: all .3s;

                @media screen and (max-width: 850px) {
                    width: 30px;
                    height: 30px;
                }

                &:hover {
                    cursor: pointer;
                    opacity: .6;
                    transition: all .3s;
                }

                svg {
                    width: 2.0274rem;
                    height: 2.0274rem;

                    @media screen and (max-width: 850px) {
                        width: 18px;
                        height: 18px;
                    }
                }
            }

        }

        .title {
            font-family: Roboto;
            font-size: 96px;
            font-style: normal;
            font-weight: 600;
            line-height: 100px; /* 104.167% */
            letter-spacing: -1.92px;

            @media screen and (max-width: 850px) {
                font-size: 48px;
                letter-spacing: -0.96px;
                line-height: normal;
            }


        }

        .smalltitle {
            color: var(--black, #0B0A0A);
            /* text L */
            font-family: Roboto;
            font-size: 4.62329rem;
            font-style: normal;
            font-weight: 400;
            line-height: 120%; /* 5.54795rem */
            letter-spacing: -0.09709rem;

            @media screen and (max-width: 850px) {
                font-size: 48px;
            }
        }

        &__suggestion {
            color: var(--grey-2, #56514D);
            text-align: center;
            /* text S */
            font-family: Roboto;
            font-size: 1.71233rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            max-width: 42.55137rem;

            @media screen and (max-width: 850px) {
                max-width: 600px;
                font-size: 16px;
            }

            
            &__link {
                color: #E75549;
                font-family: Roboto;
                font-size: 1.71233rem;
                line-height: normal;

                @media screen and (max-width: 850px) {
                    font-size: 16px;
                }

                &:hover {
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
        }
    }

    &__body {
        display: flex;
        flex-direction: column;
        row-gap: 2.05rem;
        width: 42rem;

        @media screen and (max-width: 850px) {
            max-width: 600px;
            row-gap: 16px;
        }

        &__skipButton {
            display: flex;
            align-items: center;
            column-gap: .51rem;
            color: #BCB7B3;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            font-family: "Roboto", sans-serif;
            align-self: center;

            &:hover {
                cursor: pointer;
            }


            @media screen and (max-width: 850px) {
                column-gap: 6px;
            }
        }

        &__input {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
            row-gap: 4px;

            &__title {
                font-family: Roboto;
                font-size: 1.71233rem;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                color: #56514D;

                @media screen and (max-width: 850px) {
                    font-size: 16px;
                }
            }

            &__input {
                width: 100%;
                padding: 1.71rem 2.57rem;
                border-radius: 30px;
                border: 1px solid var(--grey-1, #BCB7B3);   
                /* text S */
                font-family: Roboto;
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;

                @media screen and (max-width: 850px) {
                    padding: 20px 30px;
                    font-size: 16px;
                }

                &::placeholder {
                    color: var(--grey-1, #BCB7B3);
                }
            }
        }

        &__suggestion {
            color: var(--orange-basic, #E75549);
            /* text S */
            font-family: Roboto;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-align: start;

            @media screen and (max-width: 850px) {
                font-size: 16px;
            }

            &:hover {
                cursor: pointer;
                text-decoration: underline;
            }
        }

        &__button {
            padding: 4px 16px 4px 4px;
            align-self: center;
            border-radius: 50px;
            display: flex;
            align-items: center;
            column-gap: 8px;
            background-color: #E75549;

            &:hover {
                cursor: pointer;
            }

            &__svg {
                width: 40px;
                height: 40px;
                background-color: #FDFAFA;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;

            }

            &__text {
                color: var(--white-light, #FDFAFA);
                /* buttons */
                font-family: Oswald;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 28px; /* 155.556% */
                letter-spacing: -0.54px;
                text-transform: uppercase;

                @media screen and (max-width: 850px) {
                    font-size: 14px;
                }
            }
            
        }

        &__divider {
            width: fit-content;
            display: flex;
            column-gap: 1.03rem;
            align-items: center;
            align-self: center;

            @media screen and (max-width: 850px) {
                column-gap: 10px;
            }

            &__line {
                width: 10rem;
                height: 2px;
                background-color: #BCB7B3;

                @media screen and (max-width: 850px) {
                    width: 120px;
                }
            }

            &__text {
                color: var(--black, #0B0A0A);
                /* navigation */
                font-family: Oswald;
                font-size: 1.71233rem;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                text-transform: uppercase;

                @media screen and (max-width: 850px) {
                    font-size: 16px;
                }
            }
        }

        &__buttons {
            display: flex;
            align-items: center;
            column-gap: 2.57rem;
            align-self: center;

            @media screen and (max-width: 850px) {
                column-gap: 30px;
            }
            
            &__button {
                width: 10.27397rem;
                height: 10.27397rem;
                background-color: #FDFAFA;
                box-shadow: 0px 0px 15px rgba(79, 79, 79, 0.08);
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;

                &:hover {
                    cursor: pointer;
                }

                @media screen and (max-width: 850px) {
                    width: 80px;
                    height: 80px;
                }

                &__svg {
                    width: 5.13699rem;
                    height: 5.13699rem;

                    @media screen and (max-width: 850px) {
                        width: 40px;
                        height: 40px;
                    }
                }
            }
        }
    }

}