.subscribeServicesList {
    display: flex;
    flex-direction: column;
    margin-bottom: 14.55rem;

    &__expert-modal {
        padding: 3rem 6rem;
        display: flex;
        flex-direction: column;

        &__img {
            height: 10.3rem;
            width: 10.3rem;
            border-radius: 100%;
        }

        &__name {
            margin-top: 1.3rem;
            font-size: 3.1rem;
            line-height: 4.2rem;
            color: #0B0A0A;
        }

        &__box {
            margin-top: 2.6rem;
            display: flex;
            flex-direction: column;
            gap: 1.3rem;

            &__el {
                display: flex;
                align-items: center;
                gap: 0.5rem;

                &__img {
                    width: 2.05rem;
                    height: 2.05rem;
                }

                &__text {
                    font-family: 'Roboto';
                    font-size: 1.71rem;
                    line-height: 2rem;
                    color: #0B0A0A;
                }
            }
        }

        &__button {
            margin-top: 2.6rem;
            display: flex;
            align-items: center;
            gap: 0.7rem;
            padding: 0.35rem 1.35rem 0.35rem 0.35rem;
            background: #E75549;
            border-radius: 50px;
            text-decoration: none;

            &__img {
                height: 3.42rem;
                width: 3.42rem;
            }

            &__text {
                font-size: 1.54rem;
                line-height: 2.4rem;
                letter-spacing: -0.03em;
                text-transform: uppercase;
                color: #FDFAFA;
            }
        }
    }

    &__filters {
        margin-bottom: 2.56rem;
        display: flex;
        justify-content: space-between;
        width: 100%;

        @media (max-width: 600px) {
            -ms-overflow-style: none;
            overflow: -moz-scrollbars-none;

            ::-webkit-scrollbar {
                width: 0;
            }
        }
    }

    &__slider {
        display: block;
        width: 100%;
        justify-content: space-between;
    }

    &__title {
        font-weight: 400;
        font-size: 3rem;
        line-height: 3.25rem;
        color: $black;
        margin: 3.42rem 0;

        &__margin {
            margin: 1.71rem;

            @media (max-width: 600px) {
                margin: 10px;
            }
        }
    }

    &__list {
        width: 100%;
        flex-wrap: wrap;
        display: grid;
        gap: 5%;
        grid-template-columns: 30% 30% 30%;

        &__mobile {
            display: none;
        }

        &__el {
            cursor: pointer;

            &.deactivate {
                filter: grayscale(90%);
                cursor: auto;
            }

            &.none {
                background-color: transparent;
                box-shadow: none !important;
                cursor: inherit;
                transition: all .3s;

                &:hover {
                    box-shadow: none;
                }
            }

            width: 100% !important;
            box-shadow: 0px 3rem 4.53rem rgba(79, 79, 79, 0.07) !important;
            border-radius: 30px;
            background-color: $white;
            margin-bottom: 4.3rem !important;
            height: auto !important;
            transition: all .3s;

            &:hover {
                box-shadow: 0px 0px 1.3rem rgba(79, 79, 79, 0.1);
            }

            &__wrapper {
                display: flex;
                flex-direction: column;
                padding: 2.56rem;
                height: 100%;
            }

            &__img {
                border-radius: 15px;
                max-height: 17.5rem;
                width: 100%;
                object-fit: cover;
                margin-bottom: auto;
            }

            &__title {
                font-size: 1.71rem;
                line-height: 2.48rem;
                text-transform: uppercase;
                color: $black;
                margin: 2.05rem 0;
            }

            &__price {
                margin: -0.85rem 0 2.05rem;
                display: flex;
                align-items: center;

                &__money {
                    font-family: 'Roboto', sans-serif;
                    font-weight: 500;
                    font-size: 4.62rem;
                    line-height: 120%;
                    letter-spacing: -0.021em;
                    color: $black;
                }

                &__time {
                    margin-left: 0.9rem;
                    font-family: 'Roboto', sans-serif;
                    font-weight: 400;
                    font-size: 1.71rem;
                    line-height: 1.96rem;
                    color: #56514D;
                }
            }

            &__footer {
                display: flex;
                align-items: center;
                justify-content: space-between;

                &__button {
                    display: flex;
                    align-items: center;
                    background-color: #E75549;
                    border-radius: 50px;
                    border: none;
                    cursor: pointer;

                    &.deactivate {
                        cursor: auto;
                    }

                    &__text {
                        padding: 0 1.36rem 0 0.7rem;
                        font-size: 1.54rem;
                        line-height: 2.05rem;
                        text-transform: uppercase;
                        color: $white;
                    }

                    &__img {
                        padding: 0.34rem;
                        height: 3.42rem;
                        width: 3.42rem;
                    }
                }

                &__text {
                    padding-top: 0.34rem;
                    font-size: 1.36rem;
                    line-height: 1.62rem;
                    color: #56514D;
                    font-family: 'Roboto', sans-serif;
                    font-weight: 400;
                    white-space: nowrap;
                }

                &__hint {
                    position: relative;

                    &__img {
                        height: 2.15rem;
                        width: 2.15rem;
                        padding-top: 0.9rem;
                        cursor: pointer;
                        -webkit-mask: url('./../images/profile/services-list-question.svg') no-repeat 50% 50%;
                        mask: url('./../images/profile/services-list-question.svg') no-repeat 50% 50%;
                        background-color: #BCB7B3;
                        mask-size: 2.15rem;
                        margin-top: 2px;
                        transition: all .3s;

                        &:hover {
                            background-position: center;
                            background-color: $black;
                        }
                    }

                    &__text {
                        position: absolute;
                        bottom: 2.15rem;
                        right: 0px;
                        display: none;
                        background-color: $white;
                        box-shadow: 0px 0px 1.3rem rgba(79, 79, 79, 0.11);
                        border-radius: 1.3rem 1.3rem 0px 1.3rem;


                        &__wrapper {
                            width: 25.6rem;
                            padding: 1.71rem;
                            font-size: 1.36rem;
                            line-height: 1.62rem;
                            color: #56514D;
                            font-family: 'Roboto', sans-serif;
                            font-weight: 400;
                        }
                    }

                    &__img:hover~&__text {
                        display: block;
                    }

                }
            }
        }
    }

    &__modal {
        padding: 2.56rem 3.42rem 3.42rem 3.42rem;
        top: 53%;

        &__img {
            margin-bottom: 2.56rem;
            max-height: 23.71rem;
            width: 100%;
            object-fit: cover;
            border-radius: 15px;
        }

        &__info {
            width: auto;

            &.start {
                width: 100%;
            }

            &__title {
                font-size: 1.71rem;
                line-height: 2.56rem;
                text-transform: uppercase;
                color: #56514D;
                margin-bottom: 1.3rem;
                text-align: center;
            }

            &__list {
                display: flex;
                flex-direction: column;
                max-width: 43rem;
                margin-bottom: 1.3rem;

                &.start {
                    align-self: flex-start;
                }

                &__el {
                    display: flex;
                    margin-bottom: 1.3rem;

                    &__text {
                        font-family: 'Roboto';
                        font-size: 1.71rem;
                        line-height: 1.96rem;
                        color: $black;
                        margin-left: 0.5rem;

                        &.paid {
                            color: #14AE5C;
                        }
                    }

                    &__link {
                        text-decoration: none;
                        color: #5480A0;
                        font-family: 'Roboto', sans-serif;
                    }
                }
            }
        }

        &__button {
            background-color: #E75549;
            border-radius: 50px;
            border: none;
            letter-spacing: -0.03em;
            font-size: 1.54rem;
            line-height: 2.4rem;
            letter-spacing: -0.03em;
            text-transform: uppercase;
            color: $white;
            padding: 0.9rem 1.36rem 0.9rem 4.62rem;
            color: $white;
            background-image: url('./../images/profile/services-list-plus.svg');
            background-repeat: no-repeat;
            background-position: 0.34rem;
            background-size: 3.43rem;
            cursor: pointer;
        }
    }
}

@media (max-width: 1600px) {
    .subscribeServicesList__modal {
        transform: scale(0.85) translate(-50%, -57%);
    }
}


@media (max-width: 850px) {
    .subscribeServicesList__slider {
        display: none;
    }

    .subscribeServicesList__mobileList {
        display: block;
    }

    .subscribeServicesList__list {
        display: none;

        &__mobile {
            display: block;
        }
    }

    .subscribeServicesList__list__el {
        width: 100% !important;
        margin-bottom: 13px !important;

        &__wrapper {
            padding: 12px;
            flex-direction: row;
        }

        &__img {
            height: 120px;
            width: 120px;
            object-fit: cover;
        }

        &__box {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-left: 12px;
            width: 100%;
        }

        &__title {
            font-size: 16px;
            line-height: 24px;
            margin: 0;
        }

        &__footer {
            margin: 0;

            &__text {
                font-size: 1.2rem;
                line-height: 16px;
                margin: 0;
                padding: 0;
                display: flex;
                align-items: center;

                &__price {
                    font-family: 'Roboto';
                    font-weight: 500;
                    font-size: 2.4rem;
                    line-height: 120%;
                    letter-spacing: -0.023em;
                    color: #0B0A0A;
                }
            }

            &__button {

                &__text {
                    font-size: 14px;
                    line-height: 21px;
                    padding: 0 10px 0 4px;
                }

                &__img {
                    width: 24px;
                    height: 24px;
                }
            }

            &__hint {
                height: 24px;
                width: 24px;
                padding: 0;

                &__img {
                    height: 24px;
                    width: 24px;
                    mask-size: 24px;
                }

                &__text {
                    width: 300px;

                    &__wrapper {
                        width: auto;
                        padding: 12px;
                        font-size: 1.2rem;
                        line-height: 16px;
                    }
                }
            }
        }
    }

    .subscribeServicesList__title {
        text-align: center;
        font-size: 20px;
        line-height: 120%;
    }

    .subscribeServicesList__modal__button {
        font-size: 14px;
        line-height: 21px;
        padding: 6px 16px 6px 36px;
        background-size: 24px;
    }

    .subscribeServicesList__modal {
        transform: scale(1) translate(-50%, -50%);
    }
}