.users {
    &__table {

        &__row {
            display: grid;
            grid-template-columns: 190fr 200fr 200fr 200fr 200fr 200fr 200fr;
            gap: 5.13rem;
            padding: 1.71rem 0;
            cursor: pointer;
            border-top: 1px solid #BCB7B3;

            &:first-child {
                border: none;
            }

            &:nth-child(2) {
                border: none;
            }
        }

        &__title {
            display: grid;
            grid-template-columns: 190fr 200fr 200fr 200fr 200fr 200fr 200fr;
            gap: 5.13rem;
            margin: 2.56rem 0 1rem;
            
            &__el {
                justify-self: center;
                white-space: nowrap;
            }
        }
    }
}