.bookingDate {
    display: flex;
    flex-direction: column;
    padding-bottom: 8.56rem;
    margin-top: 17.1rem;

    &__header {
        display: flex;
        position: relative;

        &__back-button {
            position: absolute;
            border: none;
            border-radius: 100%;
            background-color: white;
            left: -5.56rem;
            top: 2.65rem;

            &__img {
                cursor: pointer;
                height: 3.42rem;
                width: 3.42rem;
            }
        }

        &__title {
            font-family: 'Roboto', sans-serif;
            font-weight: 700;
            font-size: 8.2rem;
            letter-spacing: -0.02em;
            line-height: 9.85rem;
            letter-spacing: -0.02em;
            color: $black;
            margin-bottom: 5.82rem;
        }
    }

    &__container {
        display: flex;
        justify-content: space-between;

        &__left {

            &__el.date {
                display: flex;
                min-height: 36rem;
            }

            &__el.time {
                margin-left: 3rem;
            }

            &__el.time>&__el__title {
                width: auto;
                margin-right: 1.3rem;
            }

            &__el {
                display: flex;
                margin-bottom: 2.56rem;

                &__inputTime {
                    align-self: flex-start;
                    width: 34.2rem;
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: wrap;

                    &__el {
                        border: 1px solid #BCB7B3;
                        border-radius: 50px;
                        padding: 0.5rem 3.1rem;
                        font-weight: 400;
                        font-size: 2.05rem;
                        line-height: 2.45rem;
                        color: $black;
                        text-transform: uppercase;
                        cursor: pointer;
                        margin-bottom: 1.3rem;
                        max-height: 3.51rem;
                    }

                    &__el.active {
                        background: #E75549;
                        color: $white;
                    }
                }

                &__title {
                    font-family: 'Roboto', sans-serif;
                    width: 10.25rem;
                    font-weight: 400;
                    font-size: 1.71rem;
                    line-height: 2.05rem;
                    color: #56514D;
                }

                &__text {
                    font-family: 'Roboto', sans-serif;
                    font-weight: 400;
                    font-size: 1.71rem;
                    line-height: 2.05rem;
                    color: #0B0A0A;
                }

                &__buttons {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 4.3rem;

                    &__button {
                        background: #FDFAFA;
                        border: none;
                        box-shadow: 0px 0.7rem 1.11rem rgba(79, 79, 79, 0.13);
                        border-radius: 15px;
                        cursor: pointer;

                        &__img {
                            padding: 2.05rem 1.11rem;
                        }
                    }

                    &__button.actived {
                        box-shadow: 0px 3rem 4.53rem rgba(79, 79, 79, 0.03);
                    }
                }

                &__pay-button {
                    margin: 4.3rem 0 14.55rem 2.56rem;
                    display: flex;
                    align-items: center;
                    background-color: #E75549;
                    border-radius: 50px;
                    border: none;
                    font-size: 1.54rem;
                    line-height: 2.05rem;
                    text-transform: uppercase;
                    color: $black;
                    cursor: pointer;

                    &__text {
                        padding: 1rem 1.36rem 1rem 0.35rem;
                        color: $white;
                    }

                    &__img {
                        padding: 0.35rem;
                    }
                }
            }
        }

        &__right {
            display: flex;
            flex-direction: column;
            justify-self: center;

            &__wrapper {
                padding: 2.56rem;
                background: #FDFAFA;
                box-shadow: 0px 2.05rem 4.1rem rgba(79, 79, 79, 0.1);
                border-radius: 30px;
            }

            &__title {
                margin-bottom: 2.56rem;
                font-size: 3.42rem;
                line-height: 4.1rem;
                color: $black;
            }

            &__list {

                &__el {
                    display: flex;
                    margin-bottom: 1.3rem;

                    &__text {
                        font-family: 'Roboto', sans-serif;
                        font-size: 1.71rem;
                        line-height: 2.05rem;
                        color: $black;
                        margin-left: 0.5rem;
                    }
                }
            }

            &__price {
                display: flex;
                align-items: center;

                &__result {
                    font-family: 'Roboto', sans-serif;
                    font-size: 1.71rem;
                    line-height: 2.05rem;
                    color: #56514D;
                }

                &__money {
                    font-family: 'Roboto', sans-serif;
                    margin: 0 0.9rem 0 2.56rem;
                    font-size: 4.62rem;
                    letter-spacing: -0.021em;
                    line-height: 120%;
                    color: $black;

                    @media (max-width: 850px) {
                        margin: 0 10px 0 12px;
                    }
                }

                &__monthly {
                    font-family: 'Roboto', sans-serif;
                    font-size: 1.71rem;
                    line-height: 2.05rem;
                    color: #56514D;
                }
            }
        }
    }

    &__modal {

        &__content {
            padding: 2.56rem 122px;

            &__header {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin-bottom: 25px;

                &__img {
                    margin-bottom: 1.3rem;
                }

                &__text {
                    font-size: 3.42rem;
                    line-height: 4.1rem;
                    color: #14AE5C;
                }
            }

            &__info {
                width: 27.4rem;

                &__title {
                    font-size: 2.05rem;
                    line-height: 2.45rem;
                    text-transform: uppercase;
                    color: #56514D;
                    margin-bottom: 1.3rem;
                    text-align: center;
                }

                &__list {
                    margin-bottom: 2.56rem;

                    &__el {
                        display: flex;
                        margin-bottom: 1.3rem;

                        &__text {
                            font-family: 'Roboto', sans-serif;
                            font-size: 1.71rem;
                            line-height: 2.05rem;
                            color: $black;
                            margin-left: 0.55rem;
                        }
                    }
                }
            }

            &__button {
                display: flex;
                align-items: center;
                background-color: #E75549;
                border-radius: 50px;
                border: none;
                font-size: 1.54rem;
                line-height: 2.05rem;
                text-transform: uppercase;
                color: $black;
                cursor: pointer;

                &__text {
                    padding: 1rem 1.36rem 1rem 0.35rem;
                    color: $white;
                }

                &__img {
                    padding: 0.35rem;
                }

            }
        }
    }
}

.edit-button {
    margin: 0;
}


@media (max-width: 850px) {
    .bookingDate__container__left__el.calendar {
        height: 380px;
    }

    .bookingDate__container__right__wrapper {
        position: inherit;
    }

    .bookingDate {
        margin-top: 100px;
        padding-bottom: 0;
        margin-bottom: 57px;

        &__wrap {
            padding: 0 16px;
        }

        &__header {

            &__back-button {
                top: 4px;
                left: 0;
            }


            &__title {
                font-size: 28px;
                line-height: 120%;
                margin-left: 36px;
            }
        }

        &__container {
            flex-direction: column-reverse;

            &__right {
                width: 100%;
                margin: 24px 0 43px 0;

                &__wrapper {
                    width: 100%;
                    padding: 20px;
                }

                &__title {
                    font-size: 22px;
                    line-height: 26px;
                    margin-bottom: 24px;
                }

                &__list {
                    border-bottom: 1px solid #BCB7B3;

                    &__el__text {
                        font-size: 16px;
                        line-height: 19px;
                    }
                }

                &__price {
                    margin-top: 16px;

                    &__result {
                        font-size: 16px;
                        line-height: 19px;
                    }

                    &__money {
                        font-size: 28px;
                        line-height: 120%;
                        margin-left: 12px;
                    }
                }
            }

            &__left {

                &__el {

                    &__title,
                    &__text {
                        font-size: 16px;
                        line-height: 19px;
                    }
                }
            }
        }
    }

    .bookingDate__container__left__el>.react-calendar {
        transform: none;
    }

    .bookingDate__container__left__el__inputTime {
        width: 100%;

        &__el {
            font-size: 16px;
            line-height: 24px;
            padding: 6px 16px;
        }
    }

    .bookingDate__container__left__el__pay-button {
        margin: 22px auto 0 auto;
    }
}